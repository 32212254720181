import classNames from 'classnames';
import Preact from 'preact';
import testId from '../../helpers/testId';
import interpolate from '../../helpers/interpolate';

interface Props {
  href?: string;
  label?: string;
  src: string;
  size: { width: number; height: number };
  autoConstrainSize?: boolean;
  wrapperClassName?: string;
}

const HeaderLogo: Preact.FunctionalComponent<Props> = ({
  href,
  src,
  size,
  autoConstrainSize = true,
  wrapperClassName,
  label,
}) => {
  const ratio = size.width / size.height;
  const height = autoConstrainSize
    ? interpolate({
        // Magic numbers for tweaking the logo height
        inputRange: [1, 6],
        outputRange: [43, 25],
        clip: true,
      })(ratio)
    : 60;

  const Wrapper: Preact.FunctionalComponent = ({ children }) => {
    const classes = classNames(
      testId('header__logo'),
      'jg-display-ib jg-h-full',
      'jg-space-pvxs jg-space-phxs',
      'jg-space-mlxs jg-space-mlsm@md',
    );

    if (href) {
      return (
        <a
          href={href}
          className={classNames(testId('header__logo-link'), classes)}
        >
          <span className="jg-sr-only">{label}</span>
          {children}
        </a>
      );
    }

    return <div className={classes}>{children}</div>;
  };

  return (
    <Wrapper>
      <div
        className={classNames(
          testId('header__logo-wrapper'),
          'jg-chrome-header__logo-wrapper jg-align--middle jg-position-relative',
          wrapperClassName,
        )}
        style={{
          width: height * ratio,
          height: 0,
          // The 1.05 multiplier below is a small amount of additional vertical space to
          // prevent some SVG pixel-rounding clipping that was occurring
          paddingBottom: `${(100 / ratio) * 1.05}%`,
        }}
      >
        <img
          className={classNames(
            testId('header__logo-img'),
            'jg-position-absolute jg-display-b jg-w-full jg-h-full',
          )}
          src={src}
          alt=""
        />
      </div>
    </Wrapper>
  );
};
export default HeaderLogo;
