import Preact from 'preact';
import { Consumer } from './Context';
import { LocationType } from './LocationType';

type OutputComponentProps<P> = Omit<P, 'location'>;

export default function withLocation<P extends { location: LocationType }>(
  Component: Preact.ComponentFactory<P>,
): Preact.FunctionalComponent<OutputComponentProps<P>> {
  const WrappedComponent = Component as any;

  const ComponentWithLocation: Preact.FunctionalComponent<
    OutputComponentProps<P>
  > = props => (
    <Consumer
      render={location => <WrappedComponent {...props} location={location} />}
    />
  );
  ComponentWithLocation.displayName = `withLocation(${Component.displayName ||
    Component.name})`;

  return ComponentWithLocation;
}
