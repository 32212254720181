import Preact, { Component } from 'preact';

import { SearchContent } from '../../content';
import classNames from 'classnames';
import enhanceWithClickOutside from '../../helpers/clickOutside';
import testId from '../../helpers/testId';

interface Props {
  content: Pick<SearchContent, 'placeholder' | 'submitLabel'>;
  onSubmit: (searchInput: string) => void;
  onClickOutside?: (e: Event) => void;
  className?: string;
}

interface State {
  searchInput: string;
}

export class SearchBoxComponent extends Component<Props, State> {
  state = { searchInput: '' };

  inputRef?: HTMLInputElement;

  setInputRef = (c: HTMLInputElement) => {
    this.inputRef = c;
  };

  handleInputChange = (e: Event) => {
    this.setState({
      searchInput: (e.target as HTMLInputElement).value,
    });
  };

  handleSubmit = (e: Event) => {
    e.preventDefault();
    const { onSubmit } = this.props;
    const { searchInput } = this.state;
    onSubmit(encodeURIComponent(searchInput));
  };

  // Handler for react-click-outside HOC
  handleClickOutside = (e: Event) => {
    const { onClickOutside } = this.props;
    onClickOutside && onClickOutside(e);
  };

  focusInput() {
    this.inputRef && this.inputRef.focus();
  }

  render() {
    const { content, className } = this.props;
    const { searchInput } = this.state;
    return (
      <div
        className={classNames(
          'jg-chrome-search',
          'jg-background--white jg-bdb jg-bd--very-light-grey',
          'jg-space-pvsm@md',
          className,
        )}
        id="chrome-search-box"
      >
        <div className="container jg-space-phsm@md">
          <div className="row row--no-gutter">
            <div
              className={classNames(
                'col-xs-12',
                'jg-space-phms@md',
                'col-lg-8 offset-lg-2 jg-space-phn@lg',
                'col-xl-6 offset-xl-3',
              )}
            >
              <form name="jg-chrome--search-form" onSubmit={this.handleSubmit}>
                <input
                  ref={this.setInputRef}
                  className={classNames(
                    testId('search-box__input'),
                    'jg-chrome-search__input jg-display-ib jg-align--top',
                    'jg-form-control jg-font-size--medium jg-font-size--large@md',
                    'jg-space-pln@md jg-bdn',
                  )}
                  type="search"
                  id="chromeSearch"
                  name="chromeSearch"
                  autocomplete="off"
                  placeholder={content.placeholder}
                  value={searchInput}
                  onChange={this.handleInputChange}
                  aria-labelledby="chrome-search-label"
                />
                <input
                  type="submit"
                  className={classNames(
                    testId('search-box__submit'),
                    'jg-chrome-search__button jg-display-ib jg-align--top',
                    'jg-br--0 jg-br@md',
                    'jg-btn jg-btn--newPrimary',
                  )}
                  value={content.submitLabel}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const SearchBox = enhanceWithClickOutside(SearchBoxComponent);
SearchBox.displayName = 'SearchBox';

export default SearchBox;
