export default function responsiveContentClass<
  Classes extends { [index: string]: string }
>(
  unresponsiveClass: string,
  responsiveBaseClass: string,
  responsiveClasses: Classes,
  breakpoint: undefined | keyof Classes,
): string {
  return breakpoint
    ? `${responsiveBaseClass} ${responsiveClasses[breakpoint]}`
    : unresponsiveClass;
}
