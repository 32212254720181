import Preact, { Component } from 'preact';

interface Props {
  recaptureConsents: {
    text: string;
  };
  disableConfrimButton: boolean;
  handleOptin: () => void;
  modalForHomepage: boolean;
}

interface State {
  updateRecaptureSuccess: boolean;
}

export class RecaptureModalContent extends Component<Props, State> {
  state = { updateRecaptureSuccess: false };

  handleOptin = () => {
    const { handleOptin } = this.props;
    handleOptin();
  };

  render() {
    const {
      recaptureConsents,
      disableConfrimButton,
      modalForHomepage,
    } = this.props;

    return (
      <div className="jg-modal__content">
        <div>
          <span className="jg-text--center">
            <div className="mailbox" aria-hidden="true">
              <span className="flag" />
            </div>
            <h1 className="jg-text--center">
              {modalForHomepage
                ? 'Be the first to know'
                : "Let's not lose touch!"}
            </h1>
          </span>
          <p className="jg-text--center">
            <span>{recaptureConsents.text}</span>
          </p>
          <button
            className="jg-btn"
            onClick={this.handleOptin}
            disabled={disableConfrimButton}
          >
            {modalForHomepage ? 'Opt in' : 'Confirm'}
          </button>
        </div>
        <p className="jg-text--center">
          By clicking continue you agree to our{' '}
          <a target="_blank" href="https://www.justgiving.com/info/privacy">
            Privacy policy
          </a>{' '}
          and{' '}
          <a
            target="_blank"
            href="https://www.justgiving.com/info/terms-of-service"
          >
            Terms of Service
          </a>
        </p>
      </div>
    );
  }
}

RecaptureModalContent.displayName = 'RecaptureModalContent';

export default RecaptureModalContent;
