import classNames from 'classnames';
import Preact, { Component } from 'preact';
import testId from '../../../helpers/testId';

const data = [
  {
    id: 'necessary',
    name: 'Necessary cookies',
    subtitleId: 'subtitle-necessary',
    description: `Necessary cookies help make a website usable by enabling basic
    functions like page navigation and access to secure areas of the
    website. The website cannot function properly without these
    cookies.`,
    descriptionId: 'necessary-desc',
    upArrowId: 'uparrow-necessary',
    checkboxId: 'necessarySwitch',
    showDescription: true,
    checked: false,
  },
  {
    id: 'preferences',
    name: 'Preferences cookies',
    subtitleId: 'subtitle-preference',
    description: `Preference cookies enable a website to remember information that
    changes the way the website behaves or looks, like your
    preferred language or the region that you are in.`,
    descriptionId: 'preference-desc',
    upArrowId: 'uparrow-preference',
    checkboxId: 'preferenceSwitch',
    showDescription: false,
    checked: false,
  },
  {
    id: 'performance',
    name: 'Performance cookies',
    subtitleId: 'subtitle-performance',
    description: `Statistic cookies help website owners to understand how visitors
    interact with websites by collecting and reporting information
    anonymously.`,
    descriptionId: 'performance-desc',
    upArrowId: 'uparrow-performance',
    checkboxId: 'performanceSwitch',
    showDescription: false,
    checked: false,
  },
  {
    id: 'marketing',
    name: 'Marketing cookies',
    subtitleId: 'subtitle-marketing',
    description: `Marketing cookies are used to track visitors across websites.
    The intention is to display ads that are relevant and engaging
    for the individual user and thereby more valuable for publishers
    and third party advertisers.`,
    descriptionId: 'marketing-desc',
    upArrowId: 'uparrow-marketing',
    checkboxId: 'marketingSwitch',
    showDescription: false,
    checked: false,
  },
];

declare global {
  interface Window {
    CookieConsent: any;
  }
}

interface Props {
  openManagePref: boolean;
  handleManagePref: Function;
  setHasResponse: Function;
}

interface CookiesPreference {
  id: string;
  name: string;
  subtitleId: string;
  description: string;
  checkboxId: string;
  descriptionId: string;
  upArrowId: string;
  showDescription: boolean;
  checked: boolean;
}

interface State {
  cookiesPreferences: CookiesPreference[];
  isOpen: boolean;
}

export class ManagePreferenceModal extends Component<Props, State> {
  setPreferences = (c: CookiesPreference) => {
    var isConsentWindow =
      window.CookieConsent && window.CookieConsent.consent ? true : false;
    switch (c.id) {
      case 'preferences': {
        c.checked = isConsentWindow
          ? window.CookieConsent.consent.preferences
          : false;
        break;
      }
      case 'performance': {
        c.checked = isConsentWindow
          ? window.CookieConsent.consent.statistics
          : false;
        break;
      }
      case 'marketing': {
        c.checked = isConsentWindow
          ? window.CookieConsent.consent.marketing
          : false;
        break;
      }
    }
    return c;
  };

  state: State = {
    cookiesPreferences: data.map(c => this.setPreferences(c)),
    isOpen: this.props.openManagePref || false,
  };

  closePopup = () => {
    this.props.handleManagePref(false, false);
    if (window.CookieConsent) {
      window.CookieConsent.submitCustomConsent(false, false, false);
    }
    var cb = document.getElementById('CybotCookiebotDialogv2');
    if (cb) {
      cb.style.display = 'none';
    }
    this.props.setHasResponse(true);
  };

  setDescriptionOpen = (cookiePref: CookiesPreference) => {
    const cookiesPreferences = this.state.cookiesPreferences.map(c => {
      if (c.id == cookiePref.id) {
        c.showDescription = !cookiePref.showDescription;
      }
      return c;
    });
    this.setState({ cookiesPreferences });
  };

  saveConsent = () => {
    let isPreference = this.state.cookiesPreferences[1].checked;
    let isPerformance = this.state.cookiesPreferences[2].checked;
    let isMarketing = this.state.cookiesPreferences[3].checked;
    if (window.CookieConsent)
      window.CookieConsent.submitCustomConsent(
        isPreference,
        isPerformance,
        isMarketing,
      );
    var cb = document.getElementById('CybotCookiebotDialogv2');
    if (cb) {
      cb.style.display = 'none';
    }
    this.props.handleManagePref(false, false);
    this.props.setHasResponse(true);
  };
  acceptAllCookies = () => {
    if (window.CookieConsent)
      window.CookieConsent.submitCustomConsent(true, true, true);
    var cb = document.getElementById('CybotCookiebotDialogv2');
    if (cb) {
      cb.style.display = 'none';
    }
    this.props.handleManagePref(false, false);
    this.props.setHasResponse(true);
  };
  togglePreferences = (cookiePref: CookiesPreference) => {
    const cookiesPreferences = this.state.cookiesPreferences.map(c => {
      if (c.id == cookiePref.id) {
        c.checked = !cookiePref.checked;
      }
      return c;
    });
    this.setState({ cookiesPreferences });
  };

  render() {
    const { isOpen } = this.state;
    const popupContainerClass = classNames(testId('form-popup'), 'form-popup');
    const saveChangesClass = classNames(testId('save-changes'), 'save');

    return (
      <div>
        {isOpen && (
          <div id="jg-cookiebot-form-container">
            <div className={popupContainerClass} id="popup">
              <div className="form-container">
                <div id="cookie-title">
                  <p id="cookie-title-p">Your cookie settings</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className="cookie-preferences-container">
                    {this.state.cookiesPreferences.map(s => (
                      <div>
                        <div
                          className={
                            s.showDescription
                              ? 'div-category'
                              : 'div-category-collapsed'
                          }
                          id={s.id}
                          onClick={() => this.setDescriptionOpen(s)}
                        >
                          <div style={{ display: 'flex' }}>
                            <div className="subtitle" id={s.subtitleId}>
                              {s.name}
                            </div>
                            <div>
                              <img
                                id={s.upArrowId}
                                src="https://images.justgiving.com/image/uparrow.png"
                                alt=""
                                style={{ flex: '1' }}
                                className={
                                  s.showDescription ? 'arrowup' : 'arrowdown'
                                }
                              />
                            </div>
                            <div
                              className="subtitle checkbox"
                              id={s.id == 'necessary' ? 'always-on' : ''}
                            >
                              {s.id == 'necessary' ? (
                                'Always on'
                              ) : (
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    id={s.checkboxId}
                                    checked={s.checked}
                                    onChange={() => this.togglePreferences(s)}
                                  />
                                  <span className="slider round" />
                                </label>
                              )}
                            </div>
                          </div>
                          {s.showDescription && (
                            <div id={s.descriptionId} className="desc">
                              {s.description}
                              {s.id == 'necessary' && (
                                <div>
                                  Find out more about cookies by reading our{' '}
                                  <a
                                    href="https://www.justgiving.com/about/info/cookie-notice"
                                    target="_blank"
                                  >
                                    cookie policy.
                                  </a>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        <hr className="cookiebot-hr" />
                      </div>
                    ))}
                  </div>
                  {(
                    <div className="action-footer">
                      <div>
                        <a
                          id="find-out-more"
                          onClick={this.saveConsent.bind(this)}
                        >
                          Save changes
                        </a>
                      </div>
                      <div>
                        <button
                          id="save"
                          className={saveChangesClass}
                          onClick={this.acceptAllCookies.bind(this)}
                        >
                          Accept all
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ManagePreferenceModal;
