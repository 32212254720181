import Preact from 'preact';

export interface SectionId extends Number {
  _sectionIdBrand: string;
}

export type Render = Preact.ComponentFactory<{}>;

export type RenderSection<Section> = Preact.ComponentFactory<{
  section: Section;
  sectionId: SectionId;
  isOpen: boolean;
  onClick: (sectionId: SectionId) => void;
}>;

export interface Props<Section> {
  noToggle?: boolean;
  sections: Section[];
  render: Render;
  renderSection: RenderSection<Section>;
}

export interface State {
  activeSections: SectionId[];
}

class Accordion<Section> extends Preact.Component<Props<Section>, State> {
  state: State = {
    activeSections: [],
  };

  onSectionClick = (sectionId: SectionId) => {
    this.setState((state: State) => {
      const current = state.activeSections;

      if (current.includes(sectionId)) {
        return { activeSections: current.filter(x => x !== sectionId) };
      }

      return this.props.noToggle
        ? { activeSections: current.concat([sectionId]) }
        : { activeSections: [sectionId] };
    });
  };

  renderSection = (section: Section, index: number) => {
    const { renderSection: RenderSection } = this.props;
    const { activeSections } = this.state;
    const sectionId: SectionId = index as any;
    return (
      <RenderSection
        section={section}
        sectionId={sectionId}
        isOpen={activeSections.includes(sectionId)}
        onClick={this.onSectionClick}
      />
    );
  };

  render() {
    const { sections, render: Render } = this.props;
    return <Render>{sections.map(this.renderSection)}</Render>;
  }
}

export default Accordion;
