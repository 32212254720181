import { HeaderContent } from '../content';
import { FeaturedItem, Branding, UserContent } from '../content/content';
import { getMeta } from '../helpers/documentMeta';
import { State } from './State';

export default function resolveHeaderContent(
  document: Document,
  headerContent: HeaderContent,
  state: State,
): HeaderContent {
  const resolvedBranding = resolveBranding(document, headerContent, state);

  const featuredItemUrl =
    state.featuredItemUrl ||
    getMeta(document, 'jg:chrome:featuredItemUrl') ||
    (headerContent.featuredItem &&
      headerContent.featuredItem.item &&
      headerContent.featuredItem.item.url) ||
    null;

  const featuredItemLabel =
    state.featuredItemLabel ||
    getMeta(document, 'jg:chrome:featuredItemLabel') ||
    (headerContent.featuredItem &&
      headerContent.featuredItem.item &&
      headerContent.featuredItem.item.label) ||
    null;

  const resolvedFeaturedItemItem: FeaturedItem['item'] =
    featuredItemUrl !== null && featuredItemLabel !== null
      ? {
          ...(headerContent.featuredItem && headerContent.featuredItem.item),
          url: featuredItemUrl,
          label: featuredItemLabel,
        }
      : undefined;

  const resolvedFeaturedItem: HeaderContent['featuredItem'] =
    headerContent.featuredItem || resolvedFeaturedItemItem
      ? {
          ...headerContent.featuredItem,
          item: resolvedFeaturedItemItem,
        }
      : undefined;

  const logoLinkUrl =
    state.logoLinkUrl || getMeta(document, 'jg:chrome:logoLinkUrl');

  const resolvedUserContent: HeaderContent['user'] =
    headerContent.user &&
    resolveUserContent(document, headerContent.user, state);

  const homeLink = headerContent.homeLink && {
    url: logoLinkUrl || headerContent.homeLink.url,
    label: headerContent.homeLink.label,
  };

  const isGroupedNavlinksHeader = headerContent.isGroupedNavlinksHeader || false;

  return {
    ...headerContent,
    branding: resolvedBranding,
    homeLink,
    featuredItem: resolvedFeaturedItem,
    user: resolvedUserContent,
    isGroupedNavlinksHeader
  };
}

function getMetaFloat(document: Document, key: string): null | number {
  const value = getMeta(document, key);
  if (value === null) return null;
  const num = parseFloat(value);
  return isNaN(num) ? null : num;
}

function resolveBranding(
  document: Document,
  headerContent: HeaderContent,
  state: State,
): HeaderContent['branding'] {
  const brandingColor =
    state.brandingColor || getMeta(document, 'jg:chrome:brandingColor');
  const logoImageUrl =
    state.logoImageUrl || getMeta(document, 'jg:chrome:logoImageUrl');

  if (!(brandingColor && logoImageUrl)) {
    // Not enough custom branding given to override content, ignoring
    return headerContent.branding;
  }

  const branding: Branding = {
    color: brandingColor,
    logo: {
      src: logoImageUrl,
    },
  };

  // If both width and height are given, also set the logo.size
  const logoImageWidth =
    state.logoImageWidth || getMetaFloat(document, 'jg:chrome:logoImageWidth');
  const logoImageHeight =
    state.logoImageHeight ||
    getMetaFloat(document, 'jg:chrome:logoImageHeight');

  if (logoImageWidth && logoImageHeight) {
    branding.logo.size = { width: logoImageWidth, height: logoImageHeight };
  }

  const headerColor =
    state.headerColor || getMeta(document, 'jg:chrome:headerColor');
  const headerBgColor =
    state.headerBgColor || getMeta(document, 'jg:chrome:headerBgColor');

  if (headerColor && headerBgColor) {
    branding.headerBgColor = headerBgColor;
    branding.headerColor = headerColor;
  }

  return branding;
}

function resolveUserContent(
  document: Document,
  userContent: UserContent,
  state: State,
): UserContent {
  const loginLinkUrl =
    state.loginLinkUrl ||
    getMeta(document, 'jg:chrome:loginLinkUrl') ||
    userContent.loggedOut.login.url;

  const signUpLinkUrl =
    state.signUpLinkUrl ||
    getMeta(document, 'jg:chrome:signUpLinkUrl') ||
    userContent.loggedOut.signUp.url;

  return {
    ...userContent,
    loggedOut: {
      ...userContent.loggedOut,
      login: {
        ...userContent.loggedOut.login,
        url: loginLinkUrl,
      },
      signUp: {
        ...userContent.loggedOut.signUp,
        url: signUpLinkUrl,
      },
    },
  };
}
