import AnimatedChevron from '../AnimatedChevron';
import Preact from 'preact';
import classNames from 'classnames';
import enhanceWithClickOutside from '../../helpers/clickOutside';
import testId from '../../helpers/testId';
import { clientConfig } from '../../config';
import { LocationType } from '../Location';
import { isHomepage } from '../../content/urls';
import groupingLinks from '../../helpers/groupingLinks';
import { OptimizelyUserContext } from '@optimizely/optimizely-sdk';
import { Auth } from '@justgiving/auth';

export interface MenuItem {
  kind: 'item';
  content?: Preact.ComponentChild;
  url?: string;
  wrapperClassName?: string;
  newWindow?: boolean;
  childOf?: string;
}
export const makeItem = ({
  content,
  url,
  wrapperClassName,
  newWindow = false,
  childOf
}: Pick<MenuItem, 'content' | 'url' | 'wrapperClassName' | 'newWindow' | 'childOf'>): Item => ({
  kind: 'item',
  content,
  url,
  wrapperClassName,
  newWindow,
  childOf
});

export type Separator = { kind: 'separator'; className?: string };
export const makeSeparator = ({
  className,
}: { className?: string } = {}): Separator => ({
  kind: 'separator',
  className,
});

export type Item = MenuItem | Separator;

export interface RenderButton {
  (args: { isOpen: boolean; caret: Preact.ComponentChild }):
    | Preact.ComponentChild
    | Preact.ComponentChildren;
}

interface Props {
  onButtonClick?: (e: MouseEvent) => void;
  contents?: Item[];
  button?: string | RenderButton;
  isOpen?: boolean;
  className?: string;
  menuClassName?: string;
  menuCaretClassName?: string;
  uid: string;
  ariaLabel?: string;
  textColor?: string;
  location?: LocationType;
  optimizelyUser?: OptimizelyUserContext | null;
  auth?: Auth;
  isGroupedNavlinksHeader?: boolean;
}

interface State {
  isOpen: boolean;
  isMobile: boolean;
}

const trackingClicks = (user: OptimizelyUserContext | null | undefined,
  isHomepage?: boolean
) => {
  if (user && isHomepage) {
    user.trackEvent('Click log in homepage nav');
  }
  return;
}

export const makeListItem = (item: Item, i: number,
  optimizelyUser?: OptimizelyUserContext | null | undefined,
  showGroupedNavLinks?: boolean, isHomepage?: boolean) => {
  if (item.kind === 'separator') {
    return (
      <li
        key={`separator_${i}`}
        aria-hidden
        className={classNames(
          testId('menu__item', 'menu__item--separator'),
          'jg-chrome-dropdown-menu__separator',
          'jg-bdb jg-bd--gainsboro jg-space-mvsm jg-space-mvms@md',
          item.className,
        )}
      />
    );
  }

  return (
    <li
      key={`item_${i}`}
      className={classNames(
        testId('menu__item', 'menu__item--item'),
        item.wrapperClassName,
      )}
    >
      <a
        className={classNames(
          'jg-display-b',
          'jg-text--medium-regular jg-text-no-underline@hover',
          'jg-color--empress jg-color--nero@hover',
          'jg-space-pvxs jg-space-phsm',
          'jg-space-pvn@md jg-space-mvxs@md jg-space-phmd@md',
          `${showGroupedNavLinks && 'link'}`
        )}
        href={item.url}
        target={item.newWindow && clientConfig.isEnabledBbid ? '_blank' : undefined}
        onClick={() => trackingClicks(optimizelyUser, isHomepage)}
      >
        {item.content}
        {item.newWindow && clientConfig.isEnabledBbid &&
          <span
            className='jg-chrome-dropdown-menu__external-link'
          >
            <svg fill="none" viewBox="0 0 12 12" width={12} height={12}>
              <path fill="#818B99" d="M7.333 0v1.333h2.394L3.173 7.887l.94.94 6.554-6.554v2.394H12V0m-1.333 10.667H1.333V1.333H6V0H1.333C.593 0 0 .6 0 1.333v9.334A1.333 1.333 0 0 0 1.333 12h9.334A1.334 1.334 0 0 0 12 10.667V6h-1.333v4.667Z" />
            </svg>
          </span>
        }
      </a>
    </li>
  );
};

class Menu extends Preact.Component<Props, State> {

  state = {
    isOpen: this.props.isOpen || false,
    isMobile: false
  };

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleCollapse = () => {
    this.setState({ isOpen: false });
  };

  // Handler for react-click-outside HOC
  handleClickOutside = () => {
    this.handleCollapse();
  };

  componentDidMount(): void {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(): void {
    const element = document.getElementById("menuBox") as HTMLElement;
    if (element && element.parentElement) {
      const parentElement = element.parentElement.getBoundingClientRect();
      if (!this.state.isMobile) {
        const leftPadding = parentElement && parentElement.left + "px";
        element.style.left = "-" + leftPadding;
      } else {
        element.style.left = "0";
      }
    }
  }

  handleResize = () => {
    this.setState({ ...this.state, isMobile: window.innerWidth < 568 });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  };

  renderMenuCaret = () => {
    const { menuCaretClassName } = this.props;
    const { isOpen } = this.state;

    return (
      <div
        className={classNames(
          testId('menu__menu-caret'),
          'jg-display-b jg-position-absolute',
          'jg-chrome-dropdown-menu__caret',
          'jg-anim-fade-in jg-anim-slide-up',
          { 'jg-anim-fade-in--active jg-anim-slide-up--active': isOpen },
          menuCaretClassName,
        )}
        aria-hidden
      >
        <svg
          viewBox="0 0 18 9"
          className={classNames(
            'jg-chrome-dropdown-menu__caret-stroke',
            'jg-display-b jg-position-absolute',
            'jg-color--very-light-grey',
          )}
        >
          <path d="M 0 9 L 9 1 L 18 9" />
        </svg>
        <svg
          viewBox="0 0 20 9"
          className={classNames(
            'jg-chrome-dropdown-menu__caret-fill',
            'jg-display-b jg-position-absolute',
            'jg-color--very-light-grey',
          )}
        >
          <path d="M 0 10 L 10 1 L 20 10" />
        </svg>
      </div>
    );
  };

  onButtonClick = (
    e: MouseEvent, isHomepage?: boolean,
    user?: OptimizelyUserContext | null | undefined
  ) => {
    const { onButtonClick } = this.props;

    if (user && isHomepage) {
      user.trackEvent('Click menu homepage');
    }

    if (onButtonClick) {
      onButtonClick(e);
    }

    if (e.defaultPrevented) return;

    if (this.state.isOpen) {
      this.handleCollapse();
    } else {
      this.handleOpen();
    }
  };

  render() {
    const {
      contents = [],
      button,
      className,
      menuClassName,
      uid,
      ariaLabel,
      textColor,
      location,
      optimizelyUser,
      auth,
      isGroupedNavlinksHeader
    } = this.props;

    const { isOpen } = this.state;
    let groupedLinks;

    const isUserLoggedIn = auth &&
      auth.isUserLoggedIn();

    const showGroupedNavLinks = isHomepage(location || "") && isGroupedNavlinksHeader;

    if (showGroupedNavLinks) {
      groupedLinks = groupingLinks(contents, this.state.isMobile, isUserLoggedIn as boolean);
    }

    const buttonStyles = classNames(
      testId('menu__button'),
      'jg-chrome-dropdown-menu__button',
      'jg-bdn jg-background--transparent jg-cursor-pointer',
      'jg-display-ib jg-h-full jg-space-pvn jg-space-phxs',
      'jg-text--medium-regular',
      'jg-hover-group',
      {
        'jg-chrome-dropdown-menu__button--open': isOpen,
      },
    );

    const menuListStyles = classNames(
      testId('menu__list'),
      'jg-chrome-dropdown-menu__content',
      'jg-position-absolute jg-w-full',
      'jg-space-pvsm jg-space-mtsm@md',
      'jg-bd--very-light-grey jg-bdv jg-bdh@md jg-br@md',
      'jg-background--white jg-shadow-md',
      'jg-anim-fade-in jg-anim-slide-up',
      { 'jg-anim-fade-in--active jg-anim-slide-up--active': isOpen },
      menuClassName,
    );

    const buttonCaret = (
      <AnimatedChevron
        isOpen={isOpen}
        className={classNames(
          testId('menu__button-caret'),
          'jg-chrome-dropdown-menu__button-caret',
          'jg-display-ib jg-align--vertical-middle',
          'jg-icon--medium-small@lg',
          'jg-chrome-dropdown-menu__button-caret',
        )}
        textColor={textColor}
      />
    );

    return (
      <div
        className={classNames(
          testId('menu'),
          'jg-chrome-dropdown-menu',
          'jg-text--pull-left jg-position-relative@md',
          className,
        )}
      >
        <div
          className={classNames('jg-display-ib jg-position-relative jg-h-full')}
        >
          <button
            className={buttonStyles}
            onClick={(e) => this.onButtonClick(e, isHomepage(location || ""), optimizelyUser)}
            aria-expanded={isOpen}
            aria-haspopup="true"
            aria-controls={`jg-chrome-menu-${uid}`}
            id={`jg-chrome-menu-${uid}-button`}
            aria-label={ariaLabel}
            style={textColor && { color: textColor }}
          >
            {typeof button === 'string' && [
              <span className="jg-display-ib jg-align--vertical-center">
                {button}
              </span>,
              buttonCaret,
            ]}
            {typeof button === 'function' &&
              button({ caret: buttonCaret, isOpen })}
          </button>
          {(!showGroupedNavLinks || this.state.isMobile) && this.renderMenuCaret()}
        </div>
        <nav
          id={`jg-chrome-menu-${uid}`}
          aria-labelledby={`jg-chrome-menu-${uid}-button`}
          aria-hidden={!isOpen}
        >
          {isOpen && button === 'Menu' && showGroupedNavLinks
            ?
            <div id="menuBox" className='menuContainer'>
              <div className={classNames(testId('menu__list'),
                'linksContainer',
                'jg-anim-fade-in--active',
                'jg-anim-slide-up--active')}>
                {
                  //@ts-ignore
                  groupedLinks.map((obj) => {
                    for (let heading in obj) {
                      return (
                        <div>
                          <h4 className='headingText'>{heading}</h4>
                          <ul>
                            {obj[heading].map(
                              (item, i) => makeListItem(item, i, optimizelyUser, showGroupedNavLinks, isHomepage(location || ""))
                            )}
                          </ul>
                        </div>
                      );
                    }
                  })
                }
              </div>
            </div>
            :
            <ul className={menuListStyles}>{contents.map((item, i) => makeListItem(item, i))}</ul>
          }
        </nav>
      </div>
    );
  }
}

const MenuWithClickOutside = enhanceWithClickOutside(Menu);
MenuWithClickOutside.displayName = 'Menu';

export default MenuWithClickOutside;
