import '../publicPath';
import { createAuth } from '@justgiving/auth';
import Preact, { render } from 'preact';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { clientConfig } from '../config';
import { ChromeContent } from '../content';
import resolveHeaderContent from './resolveHeaderContent';
import resolveFooterContent from './resolveFooterContent';
import { State } from './State';
import '../components/CookieConsent/cookieBotConsentCheck';
import CookieBanner from '../components/CookieConsent/CookieBanner';
import { OptimizelyUserContext, createInstance } from '@optimizely/optimizely-sdk';
import { getCookie, manageAnalyticsCookie } from '../helpers/manageAnalyticsCookie';
import isMobile from '../helpers/isMobile';

declare global {
  interface Window {
    __jgChromeContent: ChromeContent;
    jgOnRenderChrome: () => void;

    jg: {
      chrome: {
        refresh: typeof renderChrome;
      };
    };
  }
}

const content = window.__jgChromeContent;

const headerContainer = document.getElementById('jg-chrome-header');
const footerContainer = document.getElementById('jg-chrome-footer');
let optimizelyUser: OptimizelyUserContext | null;

const optimizely = createInstance({
  sdkKey: clientConfig.optimizelySdkKey,
});

const auth = createAuth({
  documentOrReq: document,
  authCookieName: clientConfig.authCookieName,
  activeGroupCookieName: clientConfig.authActiveGroupsCookieName,
  refreshAccessTokenUrl: clientConfig.refreshAccessTokenUrl,
});

manageAnalyticsCookie(auth);
let analyticsCookieValue = decodeURIComponent(
  getCookie(clientConfig.jgAnalyticsCookieName) as string
);

const loggedOutGuid =
  analyticsCookieValue &&
  analyticsCookieValue.substring(
    analyticsCookieValue.indexOf('logged_out_guid=') + 16
  );

const deviceType = isMobile(window) ? 'Mobile' : 'Desktop';

const attributes = {
  deviceType,
};

let state: State = {
  headerVariant: content.headerVariant,
  footerVariant: content.footerVariant,
  brandingColor: null,
  logoImageUrl: null,
  logoImageWidth: null,
  logoImageHeight: null,
  logoLinkUrl: null,
  featuredItemUrl: null,
  featuredItemLabel: null,
  footerBgColor: null,
  footerColor: null,
  headerBgColor: null,
  headerColor: null,
  loginLinkUrl: null,
  signUpLinkUrl: null,
};

function renderChrome({
  headerVariant,
  footerVariant,
  ...newState
}: Partial<State> = {}) {
  state = { ...state, ...newState };

  if (headerVariant) {
    if (content.headerContent[headerVariant]) {
      state.headerVariant = headerVariant;
    } else {
      console.warn(
        `JG.FrontEnd.Chrome: No content found for header variant "${headerVariant}", falling back to previous variant "${state.headerVariant
        }"`,
      );
    }
  }

  if (footerVariant) {
    if (content.footerContent[footerVariant]) {
      state.footerVariant = footerVariant;
    } else {
      console.warn(
        `JG.FrontEnd.Chrome: No content found for footer variant "${footerVariant}", falling back to previous variant "${state.footerVariant
        }"`,
      );
    }
  }

  const headerContent = content.headerContent[state.headerVariant]!;
  const footerContent = content.footerContent[state.footerVariant]!;

  const resolvedHeaderContent = resolveHeaderContent(
    document,
    headerContent,
    state,
  );

  const resolvedFooterContent = resolveFooterContent(
    document,
    footerContent,
    state,
  );

  if (headerContainer) {
    render(
      <Header
        content={resolvedHeaderContent}
        auth={auth}
        location={window.location.href}
        optimizelyUser={optimizelyUser}
      />,
      headerContainer,
      headerContainer.firstElementChild || undefined,
    );
  }

  if (footerContainer) {
    render(
      <Footer content={resolvedFooterContent} />,
      footerContainer,
      footerContainer.firstElementChild || undefined,
    );
  }

  // DO NOT render cookiebot popup on Donate page
  const isDonateEndpoint =
    window.location.hostname &&
    window.location.hostname.toLowerCase().indexOf('donate.') === 0;
  if (!isDonateEndpoint) {
    const bodyContainer = document.getElementsByTagName('body')[0];
    if (bodyContainer) {
      var cookieBannerElement = document.getElementById(
        'cookiebot-banner-popup',
      );
      if (!cookieBannerElement) {
        const newElement = document.createElement('div');
        newElement.id = 'cookiebot-banner-popup';
        newElement.className = 'jg-chrome';
        bodyContainer.insertBefore(newElement, bodyContainer.firstChild);
        cookieBannerElement = document.getElementById('cookiebot-banner-popup');
      }
      if (cookieBannerElement) {
        render(
          <CookieBanner />,
          cookieBannerElement,
          cookieBannerElement.firstElementChild || undefined,
        );
      }
    }
  }
}

if (optimizely) {
  optimizelyUser = optimizely.createUserContext(loggedOutGuid, attributes);
}

renderChrome();

window.jg =
  window.jg && window.jg.chrome
    ? window.jg
    : {
      ...window.jg,
      chrome: {
        refresh: renderChrome,
      },
    };

try {
  if (typeof window.jgOnRenderChrome === 'function') {
    window.jgOnRenderChrome();
  }
} catch (err) {
  console.error(err);
}
