import { Auth } from '@justgiving/auth';
import classNames from 'classnames';
import Preact from 'preact';
import { UserContent, MenuItem } from '../../content';
import testId from '../../helpers/testId';
import Menu, { Item, makeItem, makeSeparator } from '../Menu';
import CharityInfo from './CharityInfo';
import loggedInMenuButton from './loggedInMenuButton';
import getCharityInfoFromCookie from './getCharityInfoFromCookie';
import { clientConfig } from '../../config';

interface Props {
  auth: Auth;
  menu: UserContent['userMenu'];
  showPip: boolean;
  handleShowModal: (showModal: boolean) => void;
  textColor?: string;
}

const LoggedIn: Preact.FunctionalComponent<Props> = ({
  auth,
  menu,
  showPip,
  handleShowModal,
  textColor
}) => {
  const accessToken = auth.getAccessToken();
  const charity = getCharityInfoFromCookie();
  const showCharityInfo = menu.showCharityInfo && clientConfig.isEnabledBbid;

  if (!accessToken) {
    return null;
  }

  const { givenName, profilePictureUrl: avatar, email } = accessToken;

  const renderLoggedInButton = loggedInMenuButton({
    name: showCharityInfo && charity ? charity.name : givenName,
    avatar: showCharityInfo && charity ? charity.logo : avatar,
    showPip,
    isCharity: menu.showCharityInfo,
  });

  const canSwitchAccount =
    Array.isArray(accessToken.permissions) &&
    accessToken.permissions.length > 1;

  const toMenuItem = (item: MenuItem): Item =>
    item === 'SEPARATOR'
      ? makeSeparator()
      : makeItem({ url: item.url, content: item.label, newWindow: item.newWindow });

  const switchProfileItem =
    menu.switchProfile &&
    makeItem({
      url: menu.switchProfile.url,
      content: menu.switchProfile.label,
      wrapperClassName: testId('logged-in__switch-profile'),
    });

  const logOutItem = makeItem({
    url: menu.logOut.url,
    content: menu.logOut.label,
    wrapperClassName: testId('logged-in__log-out'),
  });

  const switchAndLogOut =
    canSwitchAccount && switchProfileItem
      ? [switchProfileItem, logOutItem]
      : [logOutItem];

  // TODO where should charity link to?
  // This is where the charity stuff happens in the menu
  const charityInfo: null | Item[] =
    auth.isCharity() && menu.showCharityInfo
      ? [
          makeItem({
            content: <CharityInfo auth={auth} userEmail={email} />,
            wrapperClassName: testId('logged-in__charity-info'),
          }),
        ]
      : null;

  const items: null | Item[] =
    menu.items.length > 0 ? menu.items.map(toMenuItem) : null;

  const allItems: Item[] = [
    ...(charityInfo || []),
    ...(charityInfo && items
      ? [
          makeSeparator({
            className: testId('logged-in__charity-info-separator'),
          }),
        ]
      : []),
    ...(items || []),
    ...(charityInfo || items
      ? [
          makeSeparator({
            className: testId('logged-in__log-out-separator'),
          }),
        ]
      : []),
    ...switchAndLogOut,
  ];

  const onButtonClick = (e: MouseEvent) => {
    if (showPip) {
      e.preventDefault();
      handleShowModal(true);
    }
  };

  return (
    <Menu
      onButtonClick={onButtonClick}
      className={classNames(
        'jg-chrome-header__user-menu',
        'jg-display-ib jg-h-full jg-space-mln@md jg-space-mlxs@xl',
      )}
      menuClassName="jg-pin-r"
      menuCaretClassName={classNames(
        'jg-space-mrxs',
        'jg-chrome-header__user-menu-avatar-caret-offset',
        'jg-chrome-header__menu-caret-offset@xl',
      )}
      button={renderLoggedInButton}
      contents={allItems}
      uid="user"
      ariaLabel={menu.label}
      textColor={textColor}
    />
  );
};

export default LoggedIn;
