import classNames from 'classnames';
import Preact from 'preact';
import { FooterContent } from '../../content';
import testId from '../../helpers/testId';
import FooterAccordion from '../FooterAccordion';
import FooterBlock from '../FooterBlock';
import FooterText from '../FooterText';

interface Props {
  content: FooterContent;
}

const backgroundColor = (bg: FooterContent['backgroundColor']) => {
  switch (bg) {
    case 'white':
      return 'jg-background--white';

    case 'grey':
      return 'jg-background--white-smoke';

    case 'custom':
      return null;
  }
};

const Footer: Preact.FunctionalComponent<Props> = ({ content }) => {
  let footerStyle;
  if (backgroundColor(content.backgroundColor) === null && content.branding) {
    footerStyle = {
      color: `${content.branding.footerColor}`,
      backgroundColor: `${content.branding.footerBgColor}`,
    };
  }
  return (
    <footer
      className={classNames(
        testId('footer'),
        'jg-chrome-footer jg-bdt jg-bd--very-light-grey',
        backgroundColor(content.backgroundColor),
      )}
      role="contentinfo"
      style={footerStyle}
    >
      {content.siteMap && !content.superNav && (
        <div>
          <div className="jg-display-n@lg">
            <FooterAccordion footerMenu={content.siteMap} />
          </div>
          <div className="jg-display-n jg-display-b@lg">
            <FooterText footerMenu={content.siteMap} />
          </div>
        </div>
      )}
      {content.siteMap && content.superNav && (
        <div>
          <FooterText
            footerMenu={content.siteMap.slice(0, 1)}
            fullWidth
            showAllItems
          />
          <FooterText footerMenu={content.siteMap.slice(1)} />
        </div>
      )}
      <div className="jg-space-mtmd jg-space-pbsm">
        <FooterBlock
          logo={content.logo}
          copyright={content.copyright}
          fcaTagline={content.fcaTagline}
          secondary={content.secondary}
          findUs={content.findUs}
          social={content.social}
          fontColor={footerStyle ? footerStyle.color : undefined}
        />
      </div>
    </footer>
  );
};

export default Footer;
