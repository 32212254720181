import { clientConfig } from './config';
import urlJoin from 'url-join';

const isAbsolute = (url: string) => /^[a-z][a-z0-9+.-]*:/.test(url);

if (!isAbsolute(clientConfig.publicPath)) {
  __webpack_public_path__ = urlJoin(
    clientConfig.baseUri,
    clientConfig.publicPath,
  );
} else {
  __webpack_public_path__ = clientConfig.publicPath;
}
