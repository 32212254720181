import cookie from 'cookie';

declare global {
  interface Window {
    Cookiebot: any;
  }
}

const setConsentCookie = () => {
  let cookiebot = window.Cookiebot;

  if (cookiebot && cookiebot.changed) { 
    
    let expires = new Date();
    const minutes = 525600; // 1 year
    expires.setTime(expires.getTime() + minutes * 60 * 1000);

    // remove our cookie if set already
    document.cookie = cookie.serialize(cookiebot.name, '', {
      path: '/',
      expires: new Date(),
      domain: '.justgiving.com',
      secure: true,
    });

    // remove cookiebot consent cookie if set already
    document.cookie = cookiebot.name + '=;Path=/;expires=Thu, 01-Jan-1970 00:00:01 GMT';

    let cookieConsent = '{' + Object.keys(cookiebot.consent).map(function(key) {
          const value = cookiebot.consent[key];
          return typeof value === 'string' || value instanceof String
            ? key + ':%27' + value + '%27'
            : key + ':' + value;
        }).join('%2C') + '}';

    // set consent cookie for all domain
    document.cookie = cookie.serialize(cookiebot.name, cookieConsent, {
      path: '/',
      expires,
      domain: '.justgiving.com',
      secure: true,
      encode: (content: string) => content,
    });
  }
};

window.addEventListener('CookiebotOnAccept', setConsentCookie, false);

window.addEventListener('CookiebotOnDecline', setConsentCookie, false);
