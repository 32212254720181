import classNames from 'classnames';
import Preact, { Component } from 'preact';
import testId from '../../../helpers/testId';

interface Props {
  openManagePref: boolean;
  handleManagePref: Function;
  setHasResponse: Function;
}

export class CookieBannerPopup extends Component<Props> {
  openManagePreference = () => {
    this.props.handleManagePref(true, false);
  };
  acceptAllCookies = () => {
    if (window.CookieConsent)
      window.CookieConsent.submitCustomConsent(true, true, true);
    var cb = document.getElementById('CybotCookiebotDialogv2');
    if (cb) {
      cb.style.display = 'none';
    }
    this.props.handleManagePref(false, false);
    this.props.setHasResponse(true);
  };

  render() {
    const BannerPopupContainerClass = classNames(
      testId('cookiebanner-popup'),
      'cookiebanner-popup',
    );
    const acceptCookiesClass = classNames(testId('accept-all-cookies'));
    return (
      <div id="jg-cookiebot-popup-container">
        <div className={BannerPopupContainerClass} id="cookiebanner-popup">
          <div id="cookiebanner-title">
            {(
              <p id="cookiebanner-title-p">
                Before you continue to <br /> JustGiving
              </p>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="cookie-banner-description-container">
              <div
                id={'popup-cookie-desc'}
                className="desc"
                style="overflow: auto"
              >
                <p>We use cookies.</p>
                {(
                  <p>
                    {' '}
                    We use cookies to enrich and personalise your user
                    experience on this site. Click “Accept cookies” to accept
                    all essential and non-essential cookies.
                    <br /> If you want to decide which non-essential cookies are
                    used on the site, click on “Settings” to modify your
                    preferences. Find out more about cookies by reading our{' '}
                    <a
                      href="https://www.justgiving.com/about/info/cookie-notice"
                      target="_blank"
                    >
                      cookie policy.
                    </a>
                  </p>
                )}
              </div>
            </div>
            <div class="row-footer">
              <div class="column-footer-1">
                {' '}
                <a
                  id="settings-footer"
                  onClick={this.openManagePreference.bind(this)}
                >
                  {' '}
                  Settings{' '}
                </a>
              </div>
              <div class="column-footer-2">
                {' '}
                <button
                  id="accept-all-Cookies"
                  className={acceptCookiesClass}
                  onClick={this.acceptAllCookies.bind(this)}
                >
                  {' '}
                  {`Accept cookies`}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CookieBannerPopup;
