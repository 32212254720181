import Preact from 'preact';

export const detectBrowser = (ua: string) => {
  try {
    const win = window as any;
    const isIE11 =
      Boolean(win.MSInputMethodContext) &&
      Boolean((document as any).documentMode);
    const isSafari = /Safari/gi.test(ua) && !/Chrome/gi.test(ua);
    let isLegacySafari = false;

    if (isSafari) {
      const versionStringIndex = ua.indexOf('Version/');
      const version = Number(
        ua.substr(versionStringIndex + 'Version/'.length).split('.')[0],
      );

      if (!isNaN(version)) {
        isLegacySafari = version <= 8;
      }
    }

    return { isIE11, isLegacySafari };
  } catch {
    return {
      renderBanner: false,
    };
  }
};
class BrowserBanner extends Preact.Component {
  state = {
    renderBanner: false,
  };
  componentDidMount() {
    const ua = navigator.userAgent;
    const { isIE11, isLegacySafari } = detectBrowser(ua);
    const renderBanner = isIE11 || isLegacySafari;

    if (renderBanner) {
      const win = window as any;
      win.heap &&
        win.heap.track &&
        win.heap.track(
          'other - page view - unsupported browser - unsupported browser',
        );
    }

    this.setState({ renderBanner });
  }

  render() {
    if (this.state.renderBanner) {
      return (
        <section className="browserBanner">
          <div className="browserBanner__container">
            <svg
              className="browserBanner__icon"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="#F25100"
              width="22"
              height="22"
            >
              <path d="M12 23.077C5.908 23.077.923 18.092.923 12S5.908.923 12 .923 23.077 5.908 23.077 12 18.092 23.077 12 23.077zm0-20.215c-4.985 0-9.138 4.061-9.138 9.138A9.1 9.1 0 0012 21.138c4.985 0 9.138-4.061 9.138-9.138S16.985 2.862 12 2.862z" />
              <path d="M12.462 7.662h-.831v-1.2h.83v1.2zm0 9.6h-.831v-6.37h.83v6.37z" />
              <path d="M12.462 17.908h-.831a.631.631 0 01-.646-.646v-6.37c0-.369.277-.646.646-.646h.83c.37 0 .647.277.647.646v6.37a.631.631 0 01-.646.646z" />
              <circle
                transform="translate(10.154 5.538)"
                cx="1.846"
                cy="1.569"
                r="1.2"
              />
            </svg>
            Looks like you're using an outdated browser. Your browser will soon
            stop working on our website. You can check the list of{' '}
            <a
              href="https://www.justgiving.com/about/info/accessibility-statement"
              target="_blank"
              rel="noopener"
            >
              browsers we support
            </a>
          </div>
        </section>
      );
    }

    return null;
  }
}

export default BrowserBanner;
