import Preact from 'preact';
import classNames from 'classnames';
import testId from '../../helpers/testId';

interface Props {
  showBanner: boolean;
  closeBanner: () => void;
  handleShowModal: (showModal: boolean) => void;
}

export class Banner extends Preact.Component<Props> {
  handleShowRecaptureModal = () => {
    const { handleShowModal, closeBanner } = this.props;
    handleShowModal(true);
    closeBanner();
  };

  onCloseBannerClick = () => {
    const { closeBanner } = this.props;
    closeBanner();
  };

  render() {
    const { showBanner } = this.props;

    return (
      <div
        className={classNames(
          testId(`recapture-banner`),
          'recapture-banner',
          {
            'banner-shadow': showBanner,
          },
          {
            'jg-hidden': !showBanner,
          },
        )}
        style={{ 'max-height': showBanner ? '200px' : '0' }}
      >
        <div className="recapture-banner-wrapper jg-position-relative container">
          <span className="mail-img" aria-hidden="true" />
          <p>
            Your friends are fundraising. Don't miss out,
            <a onClick={this.handleShowRecaptureModal}> opt in.</a>
          </p>
          <button
            aria-label="close banner"
            className="recapture-banner__close-statement-platform jg-position-absolute"
            onClick={this.onCloseBannerClick}
          >
            <span class="sr-only">Close</span>
          </button>
        </div>
      </div>
    );
  }
}

Banner.displayName = 'Banner';
export default Banner;
