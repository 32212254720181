import { Item, MenuItem } from "../components/Menu";

type structuredData = {
  [key: string]: MenuItem[];
}

type Response = structuredData[];

export default function groupingLinks(contents: Item[], isMobile: boolean, isUserLoggedIn: boolean) {
  let response: Response = [
    {
      Individuals: []
    },
    {
      Charities: []
    },
    {
      Organizations: []
    },
  ]
  if (isMobile && !isUserLoggedIn) {
    response.push({
      'Your profile': []
    });
  }
  const restructure = (data: MenuItem[]) => {
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < response.length; j++) {
        const item: MenuItem = data[i];
        const sectionHeading = item.childOf as string;
        const key = response[j];
        if (sectionHeading in response[j]) {
          const structuredData = key[sectionHeading];
          structuredData.push(item);
        }
      }
    }
  }
  restructure(contents as MenuItem[]);
  return response;
}