export type Urls = typeof urls['staging'];

const urls = {
  local: {
    Home: 'https://home.staging.justgiving.com',
    Website: 'https://www.staging.justgiving.com',
    Profile: 'https://www.staging.justgiving.com/user-account',
    SearchUrl: 'https://www.staging.justgiving.com/search?q=',
    Pages: 'https://pages.staging.justgiving.com',
    Charities: 'https://www.staging.justgiving.com/for-charities',
  },
  staging: {
    Home: 'https://home.staging.justgiving.com',
    Website: 'https://www.staging.justgiving.com',
    Profile: 'https://www.staging.justgiving.com/user-account',
    SearchUrl: 'https://www.staging.justgiving.com/search?q=',
    Pages: 'https://pages.staging.justgiving.com',
    Charities: 'https://www.staging.justgiving.com/for-charities',
  },
  production: {
    Home: 'https://home.justgiving.com',
    Website: 'https://www.justgiving.com',
    Profile: 'https://www.justgiving.com/user-account',
    SearchUrl: 'https://www.justgiving.com/search?q=',
    Pages: 'https://pages.justgiving.com',
    Charities: 'https://www.justgiving.com/for-charities',
  },
};

const homepageHosts = [
  'www.justgiving.com',
  'justgiving.com',
  'www.staging.justgiving.com',
  'staging.justgiving.com',
];

export type Env = keyof typeof urls;

export function getUrlsForEnv(env: Env) {
  return urls[env];
}

export function isHomepage(location: string): boolean {
  try {
    const url = new URL(location);

    return (
      homepageHosts.includes(url.host) &&
      (url.pathname === '/' || url.pathname === '/homepage')
    );
  } catch (err) {
    return false;
  }
}
