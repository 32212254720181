import { Auth } from '@justgiving/auth';
import makeAPIRequest from '@justgiving/http-client';
import cookie from 'cookie';
import Preact from 'preact';
import { clientConfig } from '../../config';
import RecaptureBanner from '../RecaptureBanner';
import RecaptureModal from '../RecaptureModal';
import { setRecaptureCookie } from './setRecaptureCookie';

interface Props {
  auth?: Auth;
  handleShowPip: (show: boolean) => void;
  showModal: boolean;
  handleShowModal: (showModal: boolean) => void;
}

interface State {
  showBanner: boolean;
  updateRecaptureLoading: boolean;
  showRecaptureConfirm: boolean;
  recaptureConsents: {
    text: string;
    id: number;
  };
  storkRecommendation: {
    recommendation?: string;
    storkResponseGuid?: string;
  };
  collectionLocationId?: number | null;
  modalForHomepage?: boolean;
  refreshRetries: number;
}

export class RecaptureNotice extends Preact.Component<Props, State> {
  state = {
    showBanner: false,
    updateRecaptureLoading: false,
    showRecaptureConfirm: false,
    recaptureConsents: {
      text: '',
      id: 0,
    },
    storkRecommendation: {
      storkResponseGuid: '',
    },
    collectionLocationId: null,
    modalForHomepage: true,
    refreshRetries: 0,
  };

  componentDidMount() {
    const { auth, handleShowPip } = this.props;
    const {
      recaptureCookieName,
      recaptureDisplayLocations,
      isLocal,
    } = clientConfig;
    let recaptureCookieValue = JSON.parse(
      cookie.parse(document.cookie)[recaptureCookieName] || '{}',
    );

    const pathname = window.location.pathname;
    let shouldDisplayRecapture = false;
    let locationId;
    let modalForHomepage;

    if (pathname === recaptureDisplayLocations.thankYouPage.path) {
      locationId = recaptureDisplayLocations.thankYouPage.locationId;
      modalForHomepage = false;
    } else {
      locationId = recaptureDisplayLocations.homePage.locationId;
      modalForHomepage = true;
    }

    this.setState({
      collectionLocationId: locationId,
      modalForHomepage,
    });

    shouldDisplayRecapture = Object.keys(recaptureDisplayLocations).some(
      key => pathname === recaptureDisplayLocations[key].path,
    );

    if (isLocal) {
      shouldDisplayRecapture = true;
    }

    if (
      shouldDisplayRecapture &&
      auth &&
      auth.isUserLoggedIn() &&
      (!Object.keys(recaptureCookieValue).length ||
        (recaptureCookieValue && recaptureCookieValue.hasToRecapture))
    ) {
      if (recaptureCookieValue.hasToRecapture) {
        handleShowPip(
          recaptureCookieValue.storkRecommendation.recommendation === 'pip',
        );

        this.setState({
          showBanner:
            recaptureCookieValue.storkRecommendation.recommendation !== 'pip',
          recaptureConsents: {
            id: recaptureCookieValue.consent.id,
            text: recaptureCookieValue.consent.text,
          },
          storkRecommendation: {
            storkResponseGuid:
              recaptureCookieValue.storkRecommendation.storkResponseGuid,
            recommendation:
              recaptureCookieValue.storkRecommendation.recommendation,
          },
        });
      } else {
        this.getRecaptureConsents();
      }
    }
  }

  hideRecapture = () => {
    const { handleShowPip } = this.props;
    setRecaptureCookie({ hasToRecapture: false });
    this.setState({
      showBanner: false,
    });

    handleShowPip(false);
  };

  async getStorkRecommendation() {
    const {
      storkBaseUrl,
      storkGetResourcePath,
      jgAnalyticsCookieName,
    } = clientConfig;

    const guids = cookie.parse(document.cookie)[jgAnalyticsCookieName];
    const guidsAsObject: any = {};

    guids.split('&').forEach((part: string) => {
      const _keyVal = part.split('=');
      guidsAsObject[_keyVal[0].replace(/(\_\w)/g, m => m[1].toUpperCase())] =
        _keyVal[1];
    });
    const serializedGuids = JSON.stringify(guidsAsObject);
    const url = `${storkBaseUrl}/${storkGetResourcePath}`;

    const response = await makeAPIRequest(url, {
      method: 'POST',
      credentials: 'omit',
      body: serializedGuids,
    });

    if (response.ok) {
      const result = await response.json();
      return result;
    }
  }

  async getRecaptureConsents() {
    const { auth, handleShowPip } = this.props;
    const { collectionLocationId, refreshRetries } = this.state;
    const {
      locale,
      preferencesServiceBaseUrl,
      recaptureConsentPath,
      maxRetries,
    } = clientConfig;

    const parsedAccessToken =
      auth && auth.getParsedAuthCookie && auth.getParsedAuthCookie();
    const accessToken = auth && auth.getAccessToken();
    const bearer = parsedAccessToken && parsedAccessToken.accessToken;

    const userGuid = (accessToken && accessToken.guid) || null;
    if (!userGuid) {
      return;
    }

    const url = `${preferencesServiceBaseUrl}/${recaptureConsentPath}/${userGuid}/${collectionLocationId}/${locale}`;

    auth &&
      (await auth.refreshAccessTokenIfExpired().catch(() => {
        if (refreshRetries < maxRetries) {
          this.getRecaptureConsents();
        }
        this.setState({
          refreshRetries: refreshRetries + 1,
        });
        return;
      }));

    this.setState({
      refreshRetries: 0,
    });

    const response = await makeAPIRequest(url, {
      method: 'GET',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    });

    if (response.ok) {
      const result = await response.json();
      const consent = result && result[0];
      if (consent && !consent.hasToRecaptureConsent) {
        setRecaptureCookie({ hasToRecapture: false });
      } else {
        const storkRecommendation = await this.getStorkRecommendation();

        this.setState({
          showBanner: storkRecommendation.recommendation !== 'pip',
          recaptureConsents: consent || {},
          storkRecommendation,
        });

        setRecaptureCookie({
          hasToRecapture: true,
          storkRecommendation,
          consent,
        });

        handleShowPip(storkRecommendation.recommendation === 'pip');
      }
    }
  }

  async updateRecaptureConsents() {
    const { preferencesServiceBaseUrl, maxRetries } = clientConfig;
    const {
      recaptureConsents: { id, text },
      collectionLocationId,
      refreshRetries,
    } = this.state;
    const { auth } = this.props;

    const parsedAccessToken =
      auth && auth.getParsedAuthCookie && auth.getParsedAuthCookie();
    const accessToken = auth && auth.getAccessToken();
    const bearer = parsedAccessToken && parsedAccessToken.accessToken;

    const userGuid = (accessToken && accessToken.guid) || null;
    const serializedBody = JSON.stringify({
      CollectionLocationId: collectionLocationId,
      Consents: [
        {
          consentGiven: true,
          ConsentStatementId: id,
          ConsentStatementText: text,
        },
      ],
    });

    const url = `${preferencesServiceBaseUrl}/api/v1/users/${userGuid}/consents`;

    auth &&
      (await auth.refreshAccessTokenIfExpired().catch(() => {
        if (refreshRetries < maxRetries) {
          this.setState({
            refreshRetries: refreshRetries + 1,
          });
          return this.updateRecaptureConsents();
        }
        return Promise.reject('error');
      }));

    this.setState({
      refreshRetries: 0,
    });

    const response = await makeAPIRequest(url, {
      method: 'PUT',
      credentials: 'omit',
      headers: {
        Authorization: `Bearer ${bearer}`,
        'Content-Type': 'application/json',
      },
      body: serializedBody,
    });

    if (response.ok) {
      this.updateStorkRecommendation();
    }

    return response;
  }

  async updateStorkRecommendation() {
    const {
      storkRecommendation: { storkResponseGuid },
    } = this.state;
    const { handleShowModal } = this.props;
    const { storkBaseUrl, storkUpdateResourcePath } = clientConfig;

    const serializedStorkBody = JSON.stringify({
      storkResponseGuid,
      rewardNames: ['opt-in'],
    });

    const url = `${storkBaseUrl}/${storkUpdateResourcePath}`;

    const response = await makeAPIRequest(url, {
      method: 'POST',
      credentials: 'omit',
      body: serializedStorkBody,
    });

    if (response.ok) {
      // automatically close confirmation modal after 5 seconds
      setTimeout(() => {
        handleShowModal(false);
      }, 5000);
    }
  }

  handleOptin = () => {
    this.updateRecaptureConsents()
      .then(res => {
        if (res.ok) {
          this.setState({
            updateRecaptureLoading: false,
            showRecaptureConfirm: true,
          });
        } else {
          this.setState({
            updateRecaptureLoading: false,
            showRecaptureConfirm: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          updateRecaptureLoading: false,
          showRecaptureConfirm: false,
        });
      });
  };

  render() {
    const {
      showBanner,
      updateRecaptureLoading,
      showRecaptureConfirm,
      recaptureConsents,
      modalForHomepage,
    } = this.state;
    const { showModal, handleShowModal } = this.props;

    return (
      <div>
        <RecaptureBanner
          showBanner={showBanner}
          closeBanner={this.hideRecapture}
          handleShowModal={handleShowModal}
        />

        <RecaptureModal
          recaptureConsents={recaptureConsents}
          updateRecaptureLoading={updateRecaptureLoading}
          showRecaptureConfirm={showRecaptureConfirm}
          handleOptin={this.handleOptin}
          handleShowModal={handleShowModal}
          showModal={showModal}
          modalForHomepage={!!modalForHomepage}
        />
      </div>
    );
  }
}

RecaptureNotice.displayName = 'RecaptureNotice';
export default RecaptureNotice;
