/**
 * Preact compatible adaptation of react-click-outside
 */
import Preact from 'preact';
import hoistNonReactStatic from 'hoist-non-react-statics';

interface WrappedComponent<P = {}> extends Preact.Component<P> {
  handleClickOutside?: (e: Event) => void;
}

interface EnhancedProps {
  wrappedRef?: (c: any) => void;
}

function enhanceWithClickOutside<P = {}>(
  WrappedComponent: Preact.ComponentConstructor<P>,
): Preact.ComponentConstructor<EnhancedProps & P> {
  const componentName = WrappedComponent.displayName || WrappedComponent.name;

  class EnhancedComponent extends Preact.Component<EnhancedProps & P> {
    __domNode?: HTMLElement;
    __wrappedInstance?: WrappedComponent;

    componentDidMount() {
      this.__domNode = this.__wrappedInstance && this.__wrappedInstance.base;
      document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
      document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = (e: Event) => {
      const domNode = this.__domNode;
      if (
        (!domNode || !domNode.contains(e.target as Node)) &&
        this.__wrappedInstance &&
        typeof this.__wrappedInstance.handleClickOutside === 'function'
      ) {
        this.__wrappedInstance.handleClickOutside(e);
      }
    };

    render() {
      const { wrappedRef, ...rest } = this.props;

      return (
        <WrappedComponent
          {...rest as any}
          ref={c => {
            this.__wrappedInstance = c;
            wrappedRef && wrappedRef(c);
          }}
        />
      );
    }
  }

  EnhancedComponent.displayName = `clickOutside(${componentName})`;

  return hoistNonReactStatic(EnhancedComponent, WrappedComponent);
}

export default enhanceWithClickOutside;
