import Preact from 'preact';
import { SiteMapItem } from '../../content';
import FooterTextSection from './FooterTextSection';

interface FooterTextProps {
  footerMenu: SiteMapItem[];
  fullWidth?: boolean;
  showAllItems?: boolean;
}

const FooterText: Preact.FunctionalComponent<FooterTextProps> = ({
  footerMenu,
  fullWidth = false,
  showAllItems = false,
}) => {
  return (
    <div className="container">
      <div className="row jg-space-phxs@md">
        {footerMenu.map((menuItem, idx) => {
          const key = `menu_${idx}`;
          return (
            <FooterTextSection
              key={key}
              menuItem={menuItem}
              showAllItems={showAllItems}
              fullWidth={fullWidth}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FooterText;
