export type Range = [number, number];

export default function interpolate({
  inputRange: [inputMin, inputMax],
  outputRange: [outputMin, outputMax],
  clip = false,
}: {
  inputRange: Range;
  outputRange: Range;
  clip?: boolean;
}) {
  return (x: number) => {
    const a = (x - inputMin) / (inputMax - inputMin);
    const result = a * (outputMax - outputMin) + outputMin;

    if (!clip) return result;

    const largestOutput = Math.max(outputMin, outputMax);
    const smallestOutput = Math.min(outputMin, outputMax);
    return Math.min(largestOutput, Math.max(smallestOutput, result));
  };
}
