import { Auth } from '@justgiving/auth';
import classNames from 'classnames';
import Preact from 'preact';
import urlJoin from 'url-join';
import { HeaderContent } from '../../content';
import { isHomepage } from '../../content/urls';
import responsiveContentClass from '../../helpers/responsiveContentClass';
import testId from '../../helpers/testId';
import { LocationType, withLocation } from '../Location';
import Menu, { Item, makeItem, makeSeparator } from '../Menu';
import { OptimizelyUserContext } from '@optimizely/optimizely-sdk';

interface Props {
  content: HeaderContent;
  auth?: Auth;
  location: LocationType;
  optimizelyUser?: OptimizelyUserContext | null;
  isGroupedNavlinksHeader?: boolean;
}

class HeaderMenu extends Preact.Component<Props> {
  render() {
    const { content, auth, location } = this.props;

    if (!content.menu) return null;
    // If the user/guest is logged in, there is no featuredItem,
    // and no other items, then no menu is needed
    if (
      auth &&
      (auth.isUserLoggedIn() || auth.isGuest()) &&
      !(content.featuredItem && content.featuredItem.item) &&
      content.menu.items.length === 0
    ) {
      return null;
    }

    const featuredItems =
      content.featuredItem && content.featuredItem.item
        ? [
            makeItem({
              url: content.featuredItem.item.url,
              content: content.featuredItem.item.label,
              wrapperClassName: classNames(
                testId('header-menu__featured-item-item'),
                'jg-display-n@lg',
              ),
              childOf: content.featuredItem.item.childOf,
            }),
            ...(content.menu.items.length > 0
              ? [
                  makeSeparator({
                    className: classNames(
                      testId('header-menu__featured-item-separator'),
                      'jg-display-n@lg',
                    ),
                  }),
                ]
              : []),
          ]
        : [];

    const primaryBaseItems = [
      ...featuredItems,
      ...content.menu.items.map(
        (item): Item =>
          item === 'SEPARATOR'
            ? makeSeparator({
                className: testId('header-menu__item-separator'),
              })
            : makeItem({
                url: item.url,
                content: item.label,
                wrapperClassName: testId('header-menu__item-item'),
                childOf: item.childOf
              }),
      ),
    ];

    const loggedOutItems = content.user
      ? [
          makeItem({
            content: content.user.loggedOut.login.label,
            url: urlJoin(
              content.user.loggedOut.login.url,
              !location || isHomepage(location)
                ? content.user.loggedOut.login.returnUrls.homepage
                  ? `?returnUrl=${encodeURIComponent(
                      content.user.loggedOut.login.returnUrls.homepage,
                    )}`
                  : ''
                : `?returnUrl=${encodeURIComponent(location)}`,
            ),
            wrapperClassName: classNames(
              testId('header-menu__logged-out-item'),
              'jg-display-n@md',
            ),
            childOf: content.user.loggedOut.login.childOf
          }),
          makeItem({
            content: content.user.loggedOut.signUp.label,
            url: urlJoin(
              content.user.loggedOut.signUp.url,
              location ? `?returnUrl=${encodeURIComponent(location)}` : '',
            ),
            wrapperClassName: classNames(
              testId('header-menu__logged-out-item'),
              'jg-display-n@md',
            ),
            childOf: content.user.loggedOut.signUp.childOf
          }),
        ]
      : [];

    const loggedOutItemsSeparator =
      primaryBaseItems.length > 0 &&
      primaryBaseItems[primaryBaseItems.length - 1].kind !== 'separator'
        ? [
            makeSeparator({
              className: classNames(
                testId('header-menu__logged-out-separator'),
                'jg-display-n@md',
              ),
            }),
          ]
        : [];

    // If auth status is known to be logged out and not guest login, add the
    // Login/Signup links to the main menu, on mobile
    const primaryItems =
      auth && !auth.isUserLoggedIn() && !auth.isGuest()
        ? [...primaryBaseItems, ...loggedOutItemsSeparator, ...loggedOutItems]
        : primaryBaseItems;

    if (primaryItems.length === 0) {
      return null;
    }

    return (
      <Menu
        className={classNames(
          testId('header-menu'),
          'jg-h-full jg-align--top',
          'jg-space-mlxs@md',
          responsiveContentClass(
            'jg-display-ib',
            'jg-display-ib',
            {
              md: 'jg-display-n@md',
            },
            content.menu.maxBreakpoint,
          ),
        )}
        menuClassName="jg-whitespace-no-wrap"
        menuCaretClassName={classNames(
          'jg-pin-r jg-space-mrsm',
          'jg-pin-auto@md jg-chrome-header__menu-caret-offset@lg jg-space-mrxs@lg',
        )}
        button={content.menu.label}
        contents={primaryItems}
        uid="primary"
        location={location}
        optimizelyUser={this.props.optimizelyUser}
        auth={auth}
        isGroupedNavlinksHeader={content.isGroupedNavlinksHeader}
      />
    );
  }
}

export default withLocation(HeaderMenu);
