import Preact from 'preact';
import Svg from '@justgiving/svg';
import classNames from 'classnames';
import { searchIcon } from '@justgiving/icons';
import testId from '../../helpers/testId';

interface Props {
  onClick: () => void;
  label: string;
  isOpen: boolean;
  className?: string;
}

const defaultProps = {
  onClick: () => null,
};

const SearchIcon: Preact.FunctionalComponent<Props> = ({
  onClick,
  label,
  isOpen,
  className,
}) => (
  <button
    className={classNames(
      testId('search-icon'),
      'jg-chrome-search-icon',
      'jg-bdn jg-background--transparent jg-cursor-pointer',
      'jg-space-phxs jg-space-pvn jg-position-relative',
      'jg-hover-group',
      {
        'jg-chrome-search-icon--open': isOpen,
      },
      className,
    )}
    onClick={onClick}
    aria-haspopup={true}
    aria-expanded={isOpen}
    aria-controls="chrome-search-box"
    aria-label={label}
  >
    <span
      id="chrome-search-label"
      className={classNames(
        testId('search-icon__label'),
        'jg-chrome-search-label',
        'jg-align--vertical-middle jg-text--medium-regular',
        'jg-space-mrsm jg-display-n jg-display-ib@xl',
        {
          'jg-color--empress jg-color--nero@group-hover': !isOpen,
          'jg-color--justGiving-purple': isOpen,
        },
      )}
      aria-hidden="true"
    >
      {label}
    </span>

    <span
      className={classNames(
        testId('search-icon__icon-wrapper'),
        'jg-chrome-search-icon__svg jg-display-ib jg-align--vertical-middle',
        'jg-anim--transition-all',
        {
          'jg-chrome-search-icon__svg--open': isOpen,
        },
      )}
    >
      <Svg
        className={classNames(
          testId('search-icon__icon'),
          'jg-svg--fit-svg-to-parent jg-h-full',
          {
            'jg-color--nobel jg-color--empress@group-hover': !isOpen,
            'jg-color--justGiving-purple': isOpen,
          },
        )}
        markup={searchIcon}
      />
    </span>
  </button>
);

SearchIcon.defaultProps = defaultProps;

export default SearchIcon;
