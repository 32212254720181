import { LocationProvider, LocationType } from '../Location';
import SearchBox, { SearchBoxComponent } from '../SearchBox';
import { OptimizelyUserContext } from '@optimizely/optimizely-sdk';
import { Auth } from '@justgiving/auth';
import { HeaderContent } from '../../content';
import HeaderLogo from '../HeaderLogo';
import HeaderMenu from '../HeaderMenu';
import ImgSize from '../../helpers/ImgSize';
import Preact from 'preact';
import RecaptureNotice from '../RecaptureNotice';
import SearchIcon from '../SearchIcon';
import User from '../User';
import classNames from 'classnames';
import { getVisibleColor } from '@justgiving/utils';
import jgLogoNobel from '../../components/Logo/jgLogoNobel.svg';
import responsiveContentClass from '../../helpers/responsiveContentClass';
import { setRecaptureCookie } from '../RecaptureNotice/setRecaptureCookie';
import testId from '../../helpers/testId';
import BrowserBanner from '../BrowserBanner/BrowserBanner';

interface Props {
  content: HeaderContent;
  auth?: Auth;
  location?: LocationType;
  optimizelyUser?: OptimizelyUserContext | null;
}

interface State {
  searchOpen: boolean;
  searchAlwaysOpen: boolean;
  logoSize?: { width: number; height: number };
  showPip: boolean;
  showModal: boolean;
}

class Header extends Preact.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { branding, search } = props.content;
    const searchAlwaysOpen = Boolean(search && search.searchOpen);

    this.state = {
      searchOpen: false,
      searchAlwaysOpen: searchAlwaysOpen,
      logoSize: branding && branding.logo.size,
      showPip: false,
      showModal: false,
    };
  }

  searchButtonRef?: Element;
  setSearchButtonRef = (c: Preact.Component) => {
    this.searchButtonRef = c ? c.base : undefined;
  };

  searchBoxRef?: SearchBoxComponent;
  setSearchBoxRef = (c: SearchBoxComponent) => {
    this.searchBoxRef = c;
  };

  backgroundColor = (bg: HeaderContent['backgroundColor']) => {
    switch (bg) {
      case 'white':
        return 'jg-background--white';
  
      case 'grey':
        return 'jg-background--white-smoke';
  
      case 'custom':
        return null;
    }
  };

  onLogoSize = (logoSize: { width: number; height: number }) => {
    this.setState({ logoSize });
  };

  componentWillReceiveProps(nextProps: Props) {
    const oldBranding = this.props.content.branding;
    const newBranding = nextProps.content.branding;

    if (oldBranding === newBranding) return;

    // If the image src remains unchanged, don’t update
    if (
      oldBranding &&
      newBranding &&
      oldBranding.logo.src === newBranding.logo.src
    ) {
      return;
    }

    this.setState({ logoSize: newBranding && newBranding.logo.size });
  }

  handleSearchSubmit = (searchInput: string) => {
    const { content } = this.props;
    if (!content.search) return;

    const input = searchInput.trim().toLowerCase();
    const circuitMatch = content.search.searchCircuits.find(circuit =>
      circuit.searchTerms.includes(input),
    );

    window.location.assign(
      circuitMatch
        ? circuitMatch.redirectUri
        : `${content.search.searchUrl}${encodeURIComponent(searchInput)}`,
    );
  };

  handleSearchClickOutside = (e: Event) => {
    if (
      !this.searchButtonRef ||
      !this.searchButtonRef.contains(e.target as Node)
    ) {
      this.setState({ searchOpen: false });
    }
  };

  handleSearchToggle = () => {
    this.setState(
      (state: State) => ({ searchOpen: !state.searchOpen }),
      this.focusSearchAfterToggle,
    );
  };

  focusSearchAfterToggle = () => {
    if (this.state.searchOpen) {
      // Timeout required due to transition preventing immediate focus
      setTimeout(this.focusSearchInput, 50);
    }
  };

  focusSearchInput = () => {
    this.searchBoxRef && this.searchBoxRef.focusInput();
  };

  showPip = (show: boolean) => {
    this.setState({
      showPip: show,
    });
  };

  showModal = (show: boolean) => {
    this.setState({
      showModal: show,
      showPip: false,
    });
    setRecaptureCookie({ hasToRecapture: false });
  };

  render() {
    let headerStyle;
    const { content, auth, location = null } = this.props;
    const { searchOpen, searchAlwaysOpen, logoSize, showPip, showModal } = this.state;
    const { branding } = content;

    if (this.backgroundColor(content.backgroundColor) === null && content.branding) {
      headerStyle = {
        color: `${content.branding.headerColor}`,
        backgroundColor: `${content.branding.headerBgColor}`,
      };
    }

    return (
      <LocationProvider value={location}>
        <header
          className={classNames('jg-chrome-header', {
            'jg-color--justGiving-purple': !branding,
            },
            this.backgroundColor(content.backgroundColor)
          )}
          style={headerStyle && headerStyle.color ? {color: headerStyle.color} : 
            {color: branding && branding.color && getVisibleColor(branding.color)}
          }
        >
          <div 
          className="jg-chrome-header__bar jg-position-relative jg-h-full jg-background--white jg-bdb jg-bd--very-light-grey" 
          style={headerStyle}>
            <div className="container jg-space-phn jg-h-full">
              {branding &&
                (logoSize ? (
                  <div className="jg-display-ib jg-h-full">
                    <HeaderLogo
                      href={content.homeLink && content.homeLink.url}
                      label={content.homeLink && content.homeLink.label}
                      src={branding.logo.src}
                      size={logoSize}
                      wrapperClassName={classNames({
                        'jg-chrome-header__logo-wrapper--accommodate-jg-logo': Boolean(
                          content.jgLogo,
                        ),
                        'jg-chrome-header__logo-wrapper--bbp2p':
                          branding.logo.version === 'bbP2P',
                      })}
                      autoConstrainSize={!(branding.logo.version === 'bbP2P')}
                    />

                    <HeaderMenu content={content} auth={auth} optimizelyUser={this.props.optimizelyUser}/>
                    {content.featuredItem && content.featuredItem.item && (
                      <a
                        id="chrome-start-fundraising"
                        className={classNames(
                          testId('header__featured-item'),
                          responsiveContentClass(
                            'jg-display-ib',
                            'jg-display-n',
                            {
                              md: 'jg-display-ib@md',
                              lg: 'jg-display-ib@lg',
                            },
                            content.featuredItem.minBreakpoint,
                          ),
                          'jg-h-full jg-align--top',
                          'jg-space-phxs jg-space-mlxs jg-space-mlsm@xl',
                          'jg-color--empress',
                          'jg-color--nero@hover jg-text-no-underline@hover',
                        )}
                        href={content.featuredItem.item.url}
                      >
                        <span className="jg-display-b jg-align--middle">
                          {content.featuredItem.item.label}
                        </span>
                      </a>
                    )}
                  </div>
                ) : (
                  <ImgSize src={branding.logo.src} onSize={this.onLogoSize} />
                ))}
              {auth && (
                <div className="jg-pull-right jg-h-full jg-space-prxs jg-space-prsm@md">
                  {content.search && !searchAlwaysOpen && (
                    <SearchIcon
                      ref={this.setSearchButtonRef}
                      className="jg-display-ib jg-h-full jg-align--top jg-space-mrxs jg-space-mrsm@md"
                      label={content.search.label}
                      onClick={this.handleSearchToggle}
                      isOpen={searchOpen}
                    />
                  )}
                  {content.jgLogo && (
                    <a
                      href={content.jgLogo.url}
                      className={classNames(
                        testId('header__jg-logo'),
                        'jg-display-ib jg-h-full jg-align--vertical-middle',
                        'jg-space-mrsm@md jg-space-mrxs',
                      )}
                      target={content.jgLogo.newWindow ? '_blank' : undefined}
                      rel={
                        content.jgLogo.newWindow
                          ? 'noopener noreferrer'
                          : undefined
                      }
                    >
                      <span className="jg-sr-only">{content.jgLogo.label}</span>
                      <img
                        className={classNames(
                          testId('header__jg-logo-img'),
                          'jg-chrome-header__jg-logo-img jg-display-b jg-align--middle',
                        )}
                        src={jgLogoNobel}
                        alt=""
                      />
                    </a>
                  )}
                  {content.user && (
                    <User
                      auth={auth}
                      content={content.user}
                      headerColor={content.branding && content.branding.headerColor}
                      showPip={showPip}
                      handleShowModal={this.showModal}
                    />
                  )}
                </div>
              )}
            </div>
          </div>

          {content.search && (
            <SearchBox
              wrappedRef={this.setSearchBoxRef}
              content={content.search}
              className={classNames(
                'jg-position-absolute jg-w-full jg-anim-slide-down jg-anim-fade-in',
                {
                  'jg-anim-slide-down--active jg-anim-fade-in--active': searchOpen || searchAlwaysOpen,
                },
              )}
              onSubmit={this.handleSearchSubmit}
              onClickOutside={this.handleSearchClickOutside}
            />
          )}
          <RecaptureNotice
            auth={auth}
            handleShowPip={this.showPip}
            showModal={showModal}
            handleShowModal={this.showModal}
          />
        </header>
        <BrowserBanner />
      </LocationProvider>
    );
  }
}

export default Header;
