
    var clientConfig = window.__jgChromeConfig;
    var serverConfig = null;

    if (__DEVELOPMENT__ && typeof Proxy === 'function') {
      serverConfig = new Proxy({}, {
        get: function() {
          throw new Error("serverConfig cannot be used client-side");
        }
      });
    }

    export { clientConfig, serverConfig };
  