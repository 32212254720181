import cookie from 'cookie';
import { clientConfig } from '../../config';

interface RecaptureCookie {
  hasToRecapture: boolean;
  storkRecommendation?: {
    recommendation?: string;
    storkResponseGuid?: string;
  };
  consent?: {
    text: string;
    id: number;
  };
}

export function setRecaptureCookie({
  hasToRecapture,
  storkRecommendation,
  consent,
}: RecaptureCookie) {
  const { recaptureCookieName, cookieExpireTimeInDays } = clientConfig;
  const expires = new Date();
  expires.setTime(
    expires.getTime() + cookieExpireTimeInDays * 24 * 60 * 60 * 1000,
  );

  document.cookie = cookie.serialize(
    recaptureCookieName,
    JSON.stringify({
      hasToRecapture,
      storkRecommendation,
      consent,
    }),
    {
      path: '/',
      expires,
      domain: '.justgiving.com',
    },
  );
}
