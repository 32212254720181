import Preact, { Component } from 'preact';
import { ManagePreferenceModal } from './ManagePreferenceModal/ManagePreferenceModal';
import { CookieBannerPopup } from './CookieBannerPopup/CookieBannerPopup';
import cookie from 'cookie';

interface Props {}
declare global {
  interface Window {
    CookieConsent: any;
  }
}

interface State {
  isBannerOpen: boolean;
  isManagePrefOpen: boolean;
  hasResponse: boolean;
}

export class CookieBanner extends Component<Props> {
  state: State = {
    isManagePrefOpen: false,
    isBannerOpen: true,
    hasResponse: false,
  };

  componentDidMount() {
    window.addEventListener('loadCookieBot', this.showModal);
    let hasPrevRes = window.CookieConsent ? window.CookieConsent.hasResponse : false ;
    let hasCookieResponse = ( cookie.parse(document.cookie)['CookieConsent'] || hasPrevRes )
      ? true
      : false;
    this.setState({
      isManagePrefOpen: false,
      isBannerOpen: true,
      hasResponse: hasCookieResponse,
    });
  }
  showModal = () => {
    this.setState({
      isManagePrefOpen: false,
      isBannerOpen: true,
      hasResponse: false,
    });
  };

  showManagePreference = () => {
    this.setState({
      isManagePrefOpen: true,
      isBannerOpen: false,
      hasResponse: false,
    });
  };

  setBannerAndManagePrefPopup = (
    isManagePrefValue: any,
    isBannerOpenValue: any,
  ) => {
    this.setState({
      isManagePrefOpen: isManagePrefValue,
      isBannerOpen: isBannerOpenValue,
    });
  };
  setHasResponse = (hasResponseValue: any) => {
    this.setState({
      isManagePrefOpen: this.state.isManagePrefOpen,
      isBannerOpen: this.state.isBannerOpen,
      hasResponse: hasResponseValue,
    });
  };
  render() {
    return (
      <div>
        {!this.state.hasResponse && this.state.isBannerOpen && (
          <CookieBannerPopup
            openManagePref={false}
            handleManagePref={this.setBannerAndManagePrefPopup}
            setHasResponse={this.setHasResponse}
          />
        )}
        {this.state.isManagePrefOpen && (
          <ManagePreferenceModal
            openManagePref={this.state.isManagePrefOpen}
            handleManagePref={this.setBannerAndManagePrefPopup}
            setHasResponse={this.setHasResponse}
          />
        )}
      </div>
    );
  }
}

export default CookieBanner;
