import Preact, { Component } from 'preact';

interface Props {
  handleCloseModal: () => void;
  modalForHomepage: boolean;
}

interface State {
  componentLoaded: boolean;
}

export class RecaptureModalConfirmContent extends Component<Props, State> {
  state = { componentLoaded: false };

  componentDidMount() {
    // without settimeout the loaded class is added immediately and the animation is not wokring
    setTimeout(() => {
      this.setState({
        componentLoaded: true,
      });
    }, 0);
  }

  handleCloseModal = () => {
    const { handleCloseModal } = this.props;
    handleCloseModal();
  };

  render() {
    const { componentLoaded } = this.state;
    const { modalForHomepage } = this.props;

    return (
      <div className="jg-modal__content">
        <div>
          <span className="jg-text--center">
            <div
              className={`mailbox ${componentLoaded ? 'loaded' : ''}`}
              aria-hidden="true"
            >
              <span className="flag" />
              <span className="mail-img" id="mail-img" />
            </div>
            <h1>
              {modalForHomepage ? "Yay! You're in" : 'Great to have you!'}
            </h1>
          </span>
          <p className="jg-text--center">
            {modalForHomepage ? (
              <span>
                You can change what emails you get anytime in your{' '}
                <a href="https://www.justgiving.com/user-account/notifications">
                  preferences center
                </a>
              </span>
            ) : (
              <span>
                You can change your{' '}
                <a href="https://www.justgiving.com/user-account/notifications">
                  notification preferences
                </a>{' '}
                in your account at any time.
              </span>
            )}
          </p>
          <button
            className="jg-btn jg-btn--ghost"
            onClick={this.handleCloseModal}
          >
            Got it
          </button>
        </div>
      </div>
    );
  }
}

RecaptureModalConfirmContent.displayName = 'RecaptureModalConfirmContent';

export default RecaptureModalConfirmContent;
