
import classNames from 'classnames';
import Preact from 'preact';
import jgLogo from '../../components/Logo/jgLogo.svg';
import jgLogoNobel from '../../components/Logo/jgLogoNobel.svg';
import jgBlackbaudLogo from '../../components/Logo/jgBlackbaudLogo.svg';
import jgLogoWhite from '../../components/Logo/jgLogoWhite.svg';
import { FooterContent } from '../../content';
import testId from '../../helpers/testId';
import {
  facebook_v2,
  instagram_v2,
  linkedIn_v2,
  tiktok,
  twitter_v2,
  youtube_v2
} from '@justgiving/icons';
import './FooterBlock.scss';
import Svg from '@justgiving/svg';

const socialIcons: Record<string, string> = {
  facebook: facebook_v2,
  x: twitter_v2,
  instagram: instagram_v2,
  linkedIn: linkedIn_v2,
  tiktok: tiktok,
  youtube: youtube_v2
};

type Props = Pick<
  FooterContent,
  'logo' | 'secondary' | 'copyright' | 'fcaTagline' | 'social' | 'findUs' | 'fontColor'
>;

const logoSrc = (version: FooterContent['logo']['version']) => {
  switch (version) {
    case 'jgGrey':
      return jgLogoNobel;

    case 'jgDefault':
      return jgLogo;

    case 'bbP2P':
      return jgBlackbaudLogo;

    case 'jgWhite':
      return jgLogoWhite;
  }
};

const FooterBlock: Preact.FunctionalComponent<Props> = ({
  logo,
  secondary,
  copyright,
  fcaTagline,
  social,
  findUs,
  fontColor
}) => (
  <div className="container">
    <div className="row jg-space-phxs@md jg-space-pbsm@xl">
      <div
        className={classNames(
          social && social.length > 0 ? 'col-xs-12 col-xl-9' : 'col-xs-12',
          {
            // If there are social buttons but no copyright, balance the columns
            'jg-space-ptsm@xl': Boolean(social && !copyright),
          },
        )}
      >
        <a
          href={logo.url}
          className={testId('footer-block__logo-link')}
          target={logo.newWindow ? '_blank' : undefined}
          rel={logo.newWindow ? 'noopener noreferrer' : undefined}
        >
          <span className="jg-sr-only">{logo.label}</span>
          <img
            className={classNames(
              testId('footer-block__logo-img'),
              'jg-chrome-footer__logo-wrapper jg-display-b jg-space-mtxs',
              {
                'jg-chrome-footer__logo-wrapper--jg':
                  logo.version === 'jgDefault' || logo.version === 'jgGrey' || logo.version === 'jgWhite',
                'jg-chrome-footer__logo-wrapper--bbp2p':
                  logo.version === 'bbP2P',
              },
            )}
            src={logoSrc(logo.version)}
            alt=""
          />
        </a>
        <ul className="jg-space-mbsm jg-space-mbxs@md jg-space-mtms">
          {secondary.map((item, i) => (
            <li
              key={i}
              className="jg-display-b jg-display-ib@md jg-space-mrms@md"
            >
              <a
                className={classNames(
                  testId('footer-block__secondary-link'),
                  'jg-display-b jg-space-pvxs',
                  'jg-text--medium jg-text-no-underline@hover',
                  { 'jg-color--empress jg-color--nero@hover': !fontColor },
                )}
                href={item.url}
                target={item.newWindow ? '_blank' : undefined}
                rel={item.newWindow ? 'noopener noreferrer' : undefined}
                style={(fontColor ? { color: fontColor } : undefined)}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
        {fcaTagline && (
          <div
            className={classNames(
              testId('footer-block__fcaTagline'),
              'jg-chrome-footer__legal-text',
              'jg-space-prmd jg-space-prn@lg jg-space-pbsm',
            )}
          >
            <p
              className={classNames(
                testId('footer-block__fcaTagline-label'),
                'jg-chrome-footer__fcaTagline-text',
                'jg-font-weight-regular jg-space-mbn@xl jg-space-pbn',
              )}
              style={(fontColor ? { color: fontColor } : undefined)}
            >
              {fcaTagline.label}
            </p>
            <p className={'jg-chrome-footer__fcaTagline-divider'} />
            <p
              className={classNames(
                testId('footer-block__fcaTagline-registration'),
                'jg-chrome-footer__fcaTagline-text',
                'jg-font-weight-regular jg-space-mbn',
              )}
              style={(fontColor ? { color: fontColor } : undefined)}
            >
              {fcaTagline.registration}
            </p>
          </div>
        )}
        {copyright && (
          <p
            className={classNames(
              testId('footer-block__copyright'),
              'jg-chrome-footer__legal-text',
              'jg-font-weight-light jg-space-prmd jg-space-prn@lg',
            )}
            style={(fontColor ? { color: fontColor } : undefined)}
          >
            {copyright}
          </p>
        )}
      </div>
      {social &&
        social.length > 0 && [
          <div className="col-xs-12 col-xl-3">
            <div
              className={classNames(
                testId('footer-block__social-label'),
                'jg-text--medium-light jg-space-mbsm jg-space-mtms jg-space-mtxs@xl',
              )}
            >
              {findUs}
            </div>
            <ul className={classNames(
                testId('footer-block__social-links'),
                'icon-container',
              )}>
              {social.map((item, idx) => (
                <li
                  key={idx}
                  className={classNames(
                    'icon-box'
                  )}
                >
                  <a
                    href={item.url}
                    className={classNames(
                      testId('footer-block__social-link')
                    )}
                  >
                    <span className="jg-sr-only">{item.label}</span>
                    <Svg className="icon" markup={socialIcons[item.icon]} />
                  </a>
                </li>
              ))}
            </ul>
          </div>,
        ]}
    </div>
  </div>
);

export default FooterBlock;
