import Preact from 'preact';

interface Props {
  src: string;
  onSize: (size: { width: number; height: number }) => void;
}

export default class ImgSize extends Preact.Component<Props, {}> {
  interval?: any = null;

  imgRef?: HTMLImageElement;
  setRef = (e: HTMLImageElement) => {
    this.imgRef = e;
  };

  componentDidMount() {
    this.interval = setInterval(this.sendSize, 50);
    this.sendSize();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  sendSize = () => {
    if (!this.imgRef || !this.imgRef.complete) return;
    this.props.onSize({
      width: this.imgRef.clientWidth,
      height: this.imgRef.clientHeight,
    });
  };

  render() {
    const { src } = this.props;
    return (
      <img
        ref={this.setRef}
        className="jg-invisible jg-position-absolute"
        style="left: -10000px; top: -10000px; width: 1000px; max-width: none; max-height: none;"
        src={src}
        onLoad={this.sendSize}
      />
    );
  }
}
