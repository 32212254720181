import classNames from 'classnames';
import Preact from 'preact';
import testId from '../../helpers/testId';

interface Props {
  isOpen: boolean;
  className?: string;
  textColor?: string;
}

const AnimatedChevron: Preact.FunctionalComponent<Props> = ({
  isOpen,
  className,
  textColor
}) => (
  <div
    className={classNames(
      testId('animated-chevron'),
      'jg-chrome-animated-chevron',
      { 'jg-chrome-animated-chevron--open': isOpen },
      'jg-position-relative',
      className,
    )}
  >
    {[
      'jg-chrome-animated-chevron__left-wing',
      'jg-chrome-animated-chevron__right-wing',
    ].map(wingClassName => (
      <div
        className={classNames(
          'jg-position-absolute jg-w-full jg-h-full',
          wingClassName,
        )}
      >
        <div
          className={classNames(
            'jg-chrome-animated-chevron__wing',
            'jg-position-absolute jg-anim--transition-all',
            'jg-w-full jg-h-full',
            {
              'jg-chrome-animated-chevron__wing--open': isOpen,
            },
          )}
        >
          <svg
            viewBox="0 0 12 12"
            className={classNames(
              testId('animated-chevron__left-wing-svg'),
              'jg-display-b jg-position-absolute jg-w-full jg-h-full',
              'jg-overflow-visible',
            )}
            style={textColor && {stroke: textColor}}
          >
            <path d="M 0 6 L 6 6" />
          </svg>
        </div>
      </div>
    ))}
  </div>
);

export default AnimatedChevron;
