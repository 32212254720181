import ImageWithFallback from '@justgiving/image-with-fallback';
import { RenderButton as MenuRenderButton } from '../Menu';
import Preact from 'preact';
import Svg from '@justgiving/svg';
import { avatarIcon } from '@justgiving/icons';
import classNames from 'classnames';
import { clientConfig } from '../../config';
import testId from '../../helpers/testId';
import urlJoin from 'url-join';

/**
 * Function that returns a menu-button render function
 */
const loggedInMenuButton = ({
  avatar,
  name,
  showPip,
  isCharity = false,
}: {
  avatar: string;
  name: string;
  showPip: boolean;
  isCharity?: boolean;
}): MenuRenderButton => {
  const renderMenuButton: MenuRenderButton = ({ caret }) => {
    const avatarWithFallback = (
      <ImageWithFallback
        src={
          avatar &&
          urlJoin(clientConfig.imageCDNUri, avatar, '?template=size75x75face')
        }
        alt=""
        className={classNames(
          testId('logged-in-menu-button__avatar'),
          'jg-chrome-header__user-avatar',
          isCharity ? 'jg-bd jg-br jg-bd--very-light-grey': 'jg-br--circle',
        )}
        fallback={
          <Svg markup={avatarIcon} className="jg-chrome-header__user-avatar" />
        }
      />
    );

    return [
      <div className="jg-display-ib">
        {showPip ? (
          <div className="pip-wrapper">
            <div className="pip jg-background--sunset-red jg-position-absolute jg-color--white jg-text--center jg-br--circle">
              1<span className="jg-sr-only"> pending notification</span>
            </div>
          </div>
        ) : null}
        {avatarWithFallback}
      </div>,
      <span className="jg-display-n jg-display-ib@xl jg-align--vertical-middle">
        <span
          className={classNames(
            testId('logged-in-menu-button__name'),
            'jg-space-mlsm',
          )}
        >
          {name}
        </span>
        {caret}
      </span>,
    ];
  };

  return renderMenuButton;
};

export default loggedInMenuButton;
