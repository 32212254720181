import { Auth } from '@justgiving/auth';
import Preact from 'preact';
import LoggedIn from './LoggedIn';
import LoggedOut from './LoggedOut';
import { UserContent } from '../../content/content';

interface Props {
  content: UserContent;
  showPip: boolean;
  auth: Auth;
  handleShowModal: (showModal: boolean) => void;
  headerColor?: string;
}

const User: Preact.FunctionalComponent<Props> = ({
  auth,
  content,
  showPip,
  handleShowModal,
  headerColor
}) =>
  auth.isUserLoggedIn() ? (
    <LoggedIn
      auth={auth}
      menu={content.userMenu}
      showPip={showPip}
      handleShowModal={handleShowModal}
      textColor={headerColor}
    />
  ) : !auth.isGuest() ? (
    <LoggedOut userContent={content.loggedOut} textColor={headerColor}  />
  ) : null;

export default User;
