import cookie from 'cookie';
import queryString from 'query-string';
import { clientConfig } from '../../config';
import Charity from './Charity';

const COOKIE_NAME = clientConfig.charityBrandingPublicCookieName;

const getCharityInfoFromCookie = (): null | Charity => {
  const cookieValue = cookie.parse(document.cookie)[COOKIE_NAME];
  if (typeof cookieValue === 'undefined') return null;

  const parsed = queryString.parse(cookieValue);
  if (
    !(parsed && typeof parsed.ll === 'string' && typeof parsed.n === 'string')
  )
    return null;

  return {
    name: parsed.n,
    logo: parsed.ll,
  };
};

export default getCharityInfoFromCookie;
