import { Auth } from '@justgiving/auth';
import { careIcon } from '@justgiving/icons';
import ImageWithFallback from '@justgiving/image-with-fallback';
import Svg from '@justgiving/svg';
import classNames from 'classnames';
import Preact from 'preact';
import urlJoin from 'url-join';
import { clientConfig } from '../../config';
import testId from '../../helpers/testId';
import Charity from './Charity';
import getCharityInfoFromCookie from './getCharityInfoFromCookie';

interface Props {
  auth: Auth;
  userEmail: string;
}

const charityAvatarWithFallback = (charity: Charity) => (
  <ImageWithFallback
    src={
      charity.logo &&
      urlJoin(clientConfig.imageCDNUri, charity.logo, '?template=size100x100')
    }
    alt=""
    className={classNames(
      testId('charity-info__logo'),
      'jg-icon--large jg-bd jg-br jg-bd--very-light-grey',
    )}
    fallback={
      <div
        className={classNames(
          'jg-icon--large jg-bd jg-br jg-bd--very-light-grey',
          'jg-space-ptxs',
        )}
      >
        <Svg markup={careIcon} className="jg-icon--medium-large jg-space-mha" />
      </div>
    }
  />
);

const CharityInfo: Preact.FunctionalComponent<Props> = ({ userEmail }) => {
  const charity = getCharityInfoFromCookie();

  if (charity === null) return null;

  return (
    <div className="jg-display-table">
      <div
        className={classNames(
          'jg-chrome-header__user-menu-charity-logo',
          'jg-display-table-cell jg-align--top',
        )}
      >
        {charityAvatarWithFallback(charity)}
      </div>
      <div className="jg-display-table-cell jg-align--top jg-space-plsm">
        <span
          className={classNames(
            testId('charity-info__charity-name'),
            'jg-font-size--medium color--black',
          )}
        >
          {charity.name}
        </span>
        <br />
        <span
          className={classNames(
            testId('charity-info__user-email'),
            'jg-font-size--medium jg-text--light color--grey',
          )}
        >
          {userEmail}
        </span>
      </div>
    </div>
  );
};

export default CharityInfo;
