import { FooterContent } from '../content';
import { Branding } from '../content/content';
import { getMeta } from '../helpers/documentMeta';
import { State } from './State';

export default function resolveFooterContent(
  document: Document,
  footerContent: FooterContent,
  state: State,
): FooterContent {
  const resolvedBranding = resolveBranding(document, footerContent, state);


  return {
    ...footerContent,
    branding: resolvedBranding,
  };
}

function resolveBranding(
  document: Document,
  footerContent: FooterContent,
  state: State,
): FooterContent['branding'] {
  const brandingColor =
    state.brandingColor || getMeta(document, 'jg:chrome:brandingColor');
  const logoImageUrl =
    state.logoImageUrl || getMeta(document, 'jg:chrome:logoImageUrl');

  if (!(brandingColor && logoImageUrl)) {
    return footerContent.branding;
  }

  const branding: Branding = {
    color: brandingColor,
    logo: {
      src: logoImageUrl,
    },
  };

  // If both footer color and footer background color are given set them
  const footerColor =
    state.footerColor || getMeta(document, 'jg:chrome:footerColor');
  const footerBgColor =
    state.footerBgColor || getMeta(document, 'jg:chrome:footerBgColor');

  if (footerColor && footerBgColor) {
    branding.footerBgColor = footerBgColor;
    branding.footerColor = footerColor;
  }

  return branding;
}
