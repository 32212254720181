import { clientConfig } from '../../src/config';
import { Auth } from '@justgiving/auth';

export function getCookie(key: string) {
  const cookie = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
  return cookie ? cookie.pop() : '';
}

export function manageAnalyticsCookie(auth: Auth) {
  const domain = 'domain=.justgiving.com;';
  const path = `path=/;`;
  const maxAge = `max-age=${60 * 60 * 24 * 365 * 10};`; // 10 Years
  if (!document.cookie.includes(`${clientConfig.jgAnalyticsCookieName}=`)) {
    document.cookie = `${
      clientConfig.jgAnalyticsCookieName
    }=logged_in_guid=${getLoggedInGuid(
      auth
    )}&logged_out_guid=${generateLoggedOutGUID()};${domain}${path}${maxAge}`;
  }
}

function getLoggedInGuid(auth: Auth) {
  if (auth.isUserLoggedIn()) {
    const userData = auth.getAccessToken();

    return userData ? userData.guid : '00000000-0000-0000-0000-000000000000';
  }
  return '00000000-0000-0000-0000-000000000000';
}

// Copied from koala frontend analytics
export function generateLoggedOutGUID() {
  const lut = [];
  for (let i = 0; i < 256; i++) {
    lut[i] = (i < 16 ? '0' : '') + i.toString(16);
  }

  const d0 = (Math.random() * 0xffffffff) | 0;
  const d1 = (Math.random() * 0xffffffff) | 0;
  const d2 = (Math.random() * 0xffffffff) | 0;
  const d3 = (Math.random() * 0xffffffff) | 0;
  return `${lut[d0 & 0xff] +
    lut[(d0 >> 8) & 0xff] +
    lut[(d0 >> 16) & 0xff] +
    lut[(d0 >> 24) & 0xff]}-${lut[d1 & 0xff]}${lut[(d1 >> 8) & 0xff]}-${
    lut[((d1 >> 16) & 0x0f) | 0x40]
  }${lut[(d1 >> 24) & 0xff]}-${lut[(d2 & 0x3f) | 0x80]}${
    lut[(d2 >> 8) & 0xff]
  }-${lut[(d2 >> 16) & 0xff]}${lut[(d2 >> 24) & 0xff]}${lut[d3 & 0xff]}${
    lut[(d3 >> 8) & 0xff]
  }${lut[(d3 >> 16) & 0xff]}${lut[(d3 >> 24) & 0xff]}`;
}
