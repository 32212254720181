import Accordion, { Render, RenderSection } from './Accordion';
import { Link, SiteMapItem } from '../../content';

import AnimatedChevron from '../AnimatedChevron';
import Preact from 'preact';
import classNames from 'classnames';
import testId from '../../helpers/testId';

interface Props {
  footerMenu: SiteMapItem[];
}

const render: Render = ({ children }) => (
  <nav>
    <ol>{children}</ol>
  </nav>
);

const renderItem = (item: Link) => (
  <li>
    <a
      className={classNames(
        testId('footer-accordion__section__item-link'),
        'jg-text--regular jg-display-b',
        'jg-color--empress jg-color--nero@hover jg-text-no-underline@hover',
        'jg-space-pvxs jg-space-phsm jg-space-phms@md',
      )}
      href={item.url}
    >
      {item.label}
    </a>
  </li>
);

const buttonCaret = (isOpen: boolean) => (
  <AnimatedChevron
    isOpen={isOpen}
    className={classNames(
      'jg-icon--medium-small jg-space-mrsm jg-space-mrms@md',
      'jg-stroke--nobel jg-stroke--empress@group-hover',
    )}
  />
);

const renderSection: RenderSection<SiteMapItem> = ({
  section,
  sectionId,
  isOpen,
  onClick,
}) => (
  <li className="jg-bdb jg-bd--gainsboro">
    <button
      onClick={() => onClick(sectionId)}
      className={classNames(
        'jg-background--transparent jg-h5 jg-space-mvn jg-text--pull-left jg-outline-n',
        'jg-position-relative jg-w-full jg-space-pvms',
        'jg-space-phsm jg-space-phms@md',
        'jg-cursor-pointer',
        'jg-hover-group',
      )}
      aria-expanded={isOpen}
      aria-haspopup="true"
      aria-controls={`jg-chrome-footer-${sectionId}`}
      id={`jg-chrome-footer-${sectionId}-button`}
    >
      <h2
        className={classNames(
          testId('footer-accordion__section__title'),
          'jg-space-mvn jg-display-ib',
          'jg-color--nero jg-color--black@group-hover',
          'jg-h5'
        )}
      >
        {section.title}
      </h2>
      <div className="jg-position-absolute jg-pin-t jg-pin-r jg-h-full">
        <div className="jg-align--middle">{buttonCaret(isOpen)}</div>
      </div>
    </button>
      <ol
        className={classNames(
          testId('footer-accordion__section__item-list'),
          'jg-space-pbsm',
          !isOpen && 'jg-hidden' 
        )}
        id={`jg-chrome-footer-${sectionId}`}
        aria-labelledby={`jg-chrome-footer-${sectionId}-button`}
      >
        {section.links.map(renderItem)}
      </ol>
  </li>
);

const FooterAccordion: Preact.FunctionalComponent<Props> = ({ footerMenu }) => (
  <Accordion
    sections={footerMenu}
    render={render}
    renderSection={renderSection}
  />
);

export default FooterAccordion;
