import classNames from 'classnames';
import Preact from 'preact';
import urlJoin from 'url-join';
import { UserContent } from '../../content';
import { isHomepage } from '../../content/urls';
import responsiveContentClass from '../../helpers/responsiveContentClass';
import testId from '../../helpers/testId';
import { LocationConsumer } from '../Location';

type Props = {
  userContent: UserContent['loggedOut'];
  textColor?: string;
};

const LoggedOut: Preact.FunctionalComponent<Props> = ({
  userContent,
  textColor,
}) => {
  const { login, signUp, minBreakpoint } = userContent;

  return (
    <ul
      className={classNames(
        responsiveContentClass(
          'jg-display-ib',
          'jg-display-n',
          {
            md: 'jg-display-ib@md',
          },
          minBreakpoint,
        ),
        'jg-h-full jg-align--top',
        'jg-space-mvn jg-space-mlxs@xl',
        'jg-text--medium-regular',
      )}
    >
      <li className="jg-display-ib jg-h-full">
        <LocationConsumer
          render={location => (
            <a
              className={classNames(
                testId('logged-out__item__link'),
                'jg-display-ib jg-h-full jg-space-phxs',
                !textColor
                  ? 'jg-color--empress jg-color--nero@hover jg-text-no-underline@hover'
                  : 'jg-color--nero@hover',
              )}
              style={textColor && { color: textColor }}
              href={urlJoin(
                login.url,
                !location || isHomepage(location)
                  ? login.returnUrls.homepage
                    ? `?returnUrl=${encodeURIComponent(
                        login.returnUrls.homepage,
                      )}`
                    : ''
                  : `?returnUrl=${encodeURIComponent(location)}`,
              )}
            >
              <span className="jg-display-b jg-align--middle">
                {login.label}
              </span>
            </a>
          )}
        />
      </li>
      <li className="jg-display-ib jg-h-full">
        <LocationConsumer
          render={location => (
            <a
              className={classNames(
                testId('logged-out__item__link'),
                'jg-display-ib jg-h-full jg-space-phxs',
                !textColor
                  ? 'jg-color--empress jg-color--nero@hover jg-text-no-underline@hover'
                  : 'jg-color--nero@hover',
                'jg-space-mlxs',
              )}
              style={textColor && { color: textColor }}
              href={urlJoin(
                signUp.url,
                location ? `?returnUrl=${encodeURIComponent(location)}` : '',
              )}
            >
              <span className="jg-display-b jg-align--middle">
                {signUp.label}
              </span>
            </a>
          )}
        />
      </li>
    </ul>
  );
};

export default LoggedOut;
