import Preact, { Component } from 'preact';
import classNames from 'classnames';
import RecaptureModalContent from './RecaptureModalContent';
import RecaptureModalConfirmContent from './RecaptureModalConfirmContent';

interface Props {
  recaptureConsents: {
    text: string;
  };
  updateRecaptureLoading: boolean;
  showRecaptureConfirm: boolean;
  showModal: boolean;
  handleShowModal: (showModal: boolean) => void;
  handleOptin: () => void;
  modalForHomepage: boolean;
}

interface State {}

export class RecaptureModal extends Component<Props, State> {
  componentDidUpdate() {
    const { modalForHomepage, showModal } = this.props;
    if (!modalForHomepage && showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }

  handleOptin = () => {
    const { handleOptin } = this.props;
    handleOptin();
  };

  handleCloseModal = () => {
    const { handleShowModal } = this.props;
    handleShowModal(false);
  };

  render() {
    const {
      recaptureConsents,
      updateRecaptureLoading,
      showRecaptureConfirm,
      showModal,
      modalForHomepage,
    } = this.props;

    if (!showModal) {
      return null;
    }

    return (
      <div
        className={classNames(
          'modal-overlay',
          'recapture-modal',
          'jg-color--nero',
          {
            'thank-you-page-modal': !modalForHomepage,
          },
        )}
      >
        <div className="modal-content recapture-modal-content">
          <div className="jg-modal">
            <a
              className="close-recapture-modal"
              onClick={this.handleCloseModal}
              aria-label="close modal"
            />
            {!showRecaptureConfirm ? (
              <RecaptureModalContent
                handleOptin={this.handleOptin}
                recaptureConsents={recaptureConsents}
                disableConfrimButton={updateRecaptureLoading}
                modalForHomepage={modalForHomepage}
              />
            ) : (
              <RecaptureModalConfirmContent
                handleCloseModal={this.handleCloseModal}
                modalForHomepage={modalForHomepage}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

RecaptureModal.displayName = 'RecaptureModal';

export default RecaptureModal;
